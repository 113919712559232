export function clampedMapRange(
  from: [number, number],
  to: [number, number],
  s: number
) {
  const t = getClampedT(from, s);
  return lrp(to[0], to[1], t);
}

export function getT(range: readonly [number, number], x: number) {
  return (x - range[0]) / (range[1] - range[0]);
}

export function getClampedT(range: readonly [number, number], x: number) {
  return clamp(0, 1, getT(range, x));
}

export function lrp(from: number, to: number, t: number) {
  return from + t * (to - from);
}

export function clamp(min: number, max: number, x: number) {
  return Math.max(Math.min(max, x), min);
}
