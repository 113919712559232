import { TreeNode, getParents } from "../../../../../utils/people/tree";

export function getVisibleNodeSet(
  treeNodeByDocId: Map<string, TreeNode>,
  personDocId: string
) {
  const personNode = treeNodeByDocId.get(personDocId)!;

  const visibleNodes = new Set([personNode]);

  function add(nodes: TreeNode[]) {
    for (const node of nodes) {
      visibleNodes.add(node);
    }
  }

  add(getDescendents(personNode));

  const parentNode = personNode.people.find(
    (person) => person.data.docId === personDocId
  )?.parent;
  if (parentNode) {
    add([parentNode]);
    for (const sibling of parentNode.children) {
      add([sibling.node]);
      add(getDescendents(sibling.node)); // Add nieces & nephews
    }
    for (const grandparent of parentNode.people.map((node) => node.parent)) {
      if (grandparent) {
        add(getDescendents(grandparent));
      }
    }
    add(getAncestors(parentNode));
  }

  return visibleNodes;
}

export function getNodesInSubtree(root: TreeNode): TreeNode[] {
  return [root, ...getDescendents(root)];
}

export function getDescendents(node: TreeNode): TreeNode[] {
  return node.children
    .map((child) => [child.node, ...getDescendents(child.node)])
    .flat();
}

export function getAncestors(node: TreeNode): TreeNode[] {
  return getParents(node)
    .map((parent) => [parent, ...getAncestors(parent)])
    .flat();
}
