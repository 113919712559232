import { useEffect } from "react";
import "./ImageLayer.scss";
import { restoreImageLayerFromUrl, useImageLayer } from "./image_layer_api";
import { ImageAdder } from "./imageadder/ImageAdder";
import { ImageEditor } from "./imageeditor/ImageEditor";
import { ImageViewer } from "./imageviewer/ImageViewer";

export const ImageLayer = (props: { zIndex: number }) => {
  useEffect(() => {
    window.addEventListener("popstate", restoreImageLayerFromUrl);
    return () =>
      window.removeEventListener("popstate", restoreImageLayerFromUrl);
  });

  const spec = useImageLayer();
  if (spec === undefined) {
    return null;
  }

  return (
    <div className='ImageLayer' style={{ zIndex: props.zIndex }}>
      {spec.kind === "imageViewer" && (
        <ImageViewer
          imageDocIds={spec.imageDocIds}
          initialIndex={spec.initialViewIndex ?? 0}
        />
      )}
      {spec.kind === "imageEditor" && (
        <ImageEditor
          imageDocIds={spec.imageDocIds}
          editIndex={spec.editIndex}
        />
      )}
      {spec.kind === "imageAdder" && (
        <ImageAdder sourcePersonDocId={spec.sourcePersonDocId} />
      )}
    </div>
  );
};
