import { TreeConfig } from "../render_config";
import { PersonRenderState } from "../utils/types";

import "./FamilyTreeConnections.scss";

export const FamilyTreeToParentConnection = (props: {
  personRenderState: PersonRenderState;
  parentRenderState: PersonRenderState[];
  config: TreeConfig;
}) => {
  const nodeVerticalMargin = props.config.levelSpacing - props.config.nodeSize;

  const state = props.personRenderState;

  if (state.opacity === 0) {
    return null;
  }

  const parentIsVisible = props.parentRenderState.some((s) => s.opacity > 0);

  const height = parentIsVisible
    ? nodeVerticalMargin / 2
    : nodeVerticalMargin / 4;
  return (
    <div
      className={"FamilyTreeConnection FamilyTreeConnectionVertical"}
      style={{
        height,
        left: state.x,
        top: state.y - height - props.config.nodeSize / 2,
      }}
    ></div>
  );
};

export const FamilyTreeCoupleConnection = (props: {
  peopleRenderState: PersonRenderState[];
  childrenRenderState: PersonRenderState[];
  config: TreeConfig;
}) => {
  const p0State = props.peopleRenderState[0];
  const p1State = props.peopleRenderState[1];

  const opacity = p0State.opacity;

  if (opacity === 0) {
    return null;
  }

  const coupleMiddle = (p0State.x + p1State.x) / 2;

  let familyMinX = coupleMiddle;
  let familyMaxX = coupleMiddle;
  for (const child of props.childrenRenderState) {
    if (child.opacity > 0) {
      familyMinX = Math.min(familyMinX, child.x);
      familyMaxX = Math.max(familyMaxX, child.x);
    }
  }

  const hasChildren = !!props.childrenRenderState.length;

  return (
    <div>
      <div
        className='FamilyTreeConnection FamilyTreeConnectionHorizontal'
        style={{
          left: Math.min(p0State.x, p1State.x) + props.config.nodeSize / 2,
          top: p0State.y,
          width: props.config.spouseSpacing,
        }}
      ></div>
      {(hasChildren && (
        <div
          className='FamilyTreeConnection FamilyTreeConnectionVertical'
          style={{
            height: props.config.levelSpacing / 2,
            left: coupleMiddle,
            top: p0State.y,
          }}
        ></div>
      )) ??
        ""}
      {(hasChildren && (
        <div
          className='FamilyTreeConnection FamilyTreeConnectionHorizontal'
          style={{
            left: familyMinX,
            top: (p0State.y + props.childrenRenderState[0].y) / 2,
            width: familyMaxX - familyMinX,
          }}
        ></div>
      )) ??
        ""}
    </div>
  );
};
