import { CloseIcon } from "../icons/CloseIcon";

import "./CloseButton.scss";

export const CloseButton = (props: {
  onClick: () => void;
  classes?: string;
}) => {
  return (
    <button
      onClick={props.onClick}
      className={`CloseButton ${props.classes ?? ""}`}
    >
      <CloseIcon />
    </button>
  );
};
