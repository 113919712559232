import { Timestamp } from "firebase/firestore/lite";
import { PersonDocData } from "../../../../firebase/people/people";
import {
  usePersonDocData,
  usePersonMap,
} from "../../../../firebase/people/use_person";
import { getTreeNodeByDocIdMap } from "../../../../utils/people/memoized";
import { setPage } from "../../../body/pageapi/page";
import { AnchorButton } from "../../anchorbutton/AnchorButton";
import { ProfilePic } from "../../profilepic/ProfilePic";
import "./InfoBox.scss";

export const InfoBox = (props: { docId: string; docData: PersonDocData }) => {
  const personMap = usePersonMap();

  const treeNodeByDocIdMap = personMap
    ? getTreeNodeByDocIdMap(personMap)
    : undefined;

  const node = treeNodeByDocIdMap?.get(props.docId);
  const parentNode = props.docData.parent1DocId
    ? treeNodeByDocIdMap?.get(props.docData.parent1DocId)
    : undefined;

  return (
    <div>
      {props.docData.profileImageDocId && (
        <div className='InfoBox-ProfilePicContainer'>
          <ProfilePic
            docId={props.docId}
            data={props.docData}
            size={128}
            classes='InfoBox-ProfilePic'
            withSlideshow={{ lingerTimeMs: 5000, transitionTimeMs: 500 }}
          />
        </div>
      )}{" "}
      <StringField
        label='Name'
        value={props.docData.name}
        classes='InfoBox-DataField'
      />
      <StringField
        label='Nicknames'
        value={props.docData.nicknames}
        classes='InfoBox-DataField'
      />
      <DateField
        label='Birthday'
        value={props.docData.birthday}
        classes='InfoBox-DataField'
      />
      <PersonField
        label='Spouse'
        docId={props.docData.spouseDocId}
        classes='InfoBox-DataField'
      />
      <PersonListField
        label='Parents'
        docIds={filterTruthy([
          props.docData.parent1DocId,
          props.docData.parent2DocId,
        ])}
        classes='InfoBox-DataField'
      />
      <PersonListField
        label='Siblings'
        docIds={filterTruthy(
          parentNode?.children
            .map((p) => p.data.docId)
            .filter((docId) => docId !== props.docId)
        )}
        classes='InfoBox-DataField'
      />
      <PersonListField
        label='Children'
        docIds={node?.children.map((p) => p.data.docId)}
        classes='InfoBox-DataField'
      />
    </div>
  );
};

const StringField = (props: {
  label: string;
  value?: string;
  classes?: string;
}) => {
  if (!props.value) {
    return null;
  }
  return (
    <div className={props.classes ?? ""}>
      {props.label}: {props.value}
    </div>
  );
};

const DateField = (props: {
  label: string;
  value?: Timestamp;
  classes?: string;
}) => {
  if (!props.value) {
    return null;
  }
  return (
    <div className={props.classes ?? ""}>
      {props.label}:{" "}
      {props.value.toDate().toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
      })}
    </div>
  );
};

const PersonField = (props: {
  label: string;
  docId?: string;
  classes?: string;
}) => {
  if (!props.docId) {
    return null;
  }
  return (
    <div className={props.classes ?? ""}>
      {props.label}: <PersonLink docId={props.docId} />
    </div>
  );
};

const PersonListField = (props: {
  label: string;
  docIds: string[] | undefined;
  classes?: string;
}) => {
  if (!props.docIds || props.docIds.length === 0) {
    return null;
  }
  return (
    <div className={props.classes ?? ""}>
      {props.label}:{" "}
      {props.docIds.map((docId, i) => [
        i > 0 ? ", " : "",
        <PersonLink docId={docId} key={docId} />,
      ])}
    </div>
  );
};

export const PersonLink = (props: { docId: string }) => {
  const person = usePersonDocData(props.docId);

  if (!person) {
    return null;
  }

  return (
    <AnchorButton
      onClick={() => setPage({ kind: "person", personDocId: props.docId })}
    >
      {person.name}
    </AnchorButton>
  );
};

function filterTruthy<T>(list?: (T | undefined)[]): T[] {
  return (list?.filter((v) => !!v) as T[]) ?? [];
}
