import { Collection } from "@jishikura/firebase-react";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";
import * as firebaseConfig from "./config.json";
import { ImageDocData } from "./images/types";
import { PersonDocData } from "./people/people";
import { Storage } from "./storage/storage";
import { UserDocData } from "./users/users";

// Initialize Firebase
const FIREBASE_APP = initializeApp(firebaseConfig);

export const AUTH_PROVIDER = new GoogleAuthProvider();

export class Firebase {
  static instance?: Firebase;

  private readonly dbInternal: Firestore;

  private peopleInternal?: Collection<PersonDocData>;
  private imagesInternal?: Collection<ImageDocData>;
  private usersInternal?: Collection<UserDocData>;
  private storageInternal?: Storage;

  private constructor() {
    this.dbInternal = getFirestore(FIREBASE_APP);
  }

  private getPeople() {
    if (this.peopleInternal === undefined) {
      this.peopleInternal = new Collection<PersonDocData>(
        this.dbInternal,
        "people"
      );
    }
    return this.peopleInternal;
  }

  private getImages() {
    if (this.imagesInternal === undefined) {
      this.imagesInternal = new Collection<ImageDocData>(
        this.dbInternal,
        "images"
      );
    }
    return this.imagesInternal;
  }
  private getUsers() {
    if (this.usersInternal === undefined) {
      this.usersInternal = new Collection<UserDocData>(
        this.dbInternal,
        "users"
      );
    }
    return this.usersInternal;
  }

  private getStorage() {
    if (this.storageInternal === undefined) {
      this.storageInternal = new Storage(getStorage(FIREBASE_APP));
    }
    return this.storageInternal;
  }

  static get db() {
    return Firebase.getInstance().dbInternal;
  }

  static get auth() {
    return getAuth();
  }

  static get people() {
    return Firebase.getInstance().getPeople();
  }

  static get images() {
    return Firebase.getInstance().getImages();
  }

  static get users() {
    return Firebase.getInstance().getUsers();
  }

  static get storage() {
    return Firebase.getInstance().getStorage();
  }

  static getInstance() {
    if (Firebase.instance === undefined) {
      Firebase.instance = new Firebase();
    }
    return Firebase.instance;
  }
}
