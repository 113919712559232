import { useSyncExternalStore } from "react";
import { Firebase } from "../firebase";

export function useImageDocData(docId: string) {
  return useSyncExternalStore(...Firebase.images.syncValue(docId));
}

export function useImageDocMap(docIds: string[]) {
  return useSyncExternalStore(...Firebase.images.syncMapView(docIds));
}
