import { ImageDoc } from "../../../firebase/images/types";
import { showImageViewer } from "../../imagelayer/image_layer_api";
import { StoredImage } from "../storedimage/StoredImage";

export const Thumbnail = (props: {
  imageDoc: ImageDoc;
  altText: string;
  classes?: string;
  options?: {
    linkToViewImage?: {
      initialViewIndex: number;
      imageDocIds: string[];
    };
  };
}) => {
  return (
    <StoredImage
      storedImage={props.imageDoc.thumbnailImage}
      altText={props.altText}
      classes={props.classes}
      onClick={
        props.options?.linkToViewImage
          ? () => showImageViewer(props.options!.linkToViewImage!)
          : undefined
      }
    />
  );
};
