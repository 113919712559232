import { blend } from "../color";
import { Relation } from "./relation";

export const ANCESTOR_GEN = "rgba(137, 113, 208, 1)";
export const SAME_GEN = "rgba(125, 172, 228, 1)";
export const DESCENDENT_GEN = "rgba(149, 232, 215, 1)";

export const SELECTED = "rgba(255, 255, 0, 1)";

export function generationColor(diff: number): string {
  if (diff === 0) {
    return SAME_GEN;
  } else if (diff > 0) {
    return blend(SAME_GEN, DESCENDENT_GEN, diff / 3);
  } else {
    return blend(SAME_GEN, ANCESTOR_GEN, Math.abs(diff) / 3);
  }
}

export function relationBorder(relation?: Relation): string {
  let style: string = "";
  let width: string = "";
  if (!relation) {
    style = "dashed";
    width = "1px";
  } else {
    if (relation.byBlood) {
      style = "double";
      width = "3px";
    } else {
      style = "dashed";
      width = "1px";
    }
  }
  return generationColor(relation?.depthDelta ?? 0) + " " + width + " " + style;
}
