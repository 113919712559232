import { default as libClone } from "clone";
import { default as fastDeepEqual } from "fast-deep-equal";

export function clone<T>(obj: T): T {
  return libClone(obj);
}

export function deepEquals<T>(a: T, b: T): boolean {
  return fastDeepEqual(a, b);
}
