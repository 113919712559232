import { average, max, min, truthyList } from "../../../../../utils/list";
import { Person, TreeNode } from "../../../../../utils/people/tree";
import { TreeConfig } from "../render_config";
import { getAncestors, getDescendents, getVisibleNodeSet } from "./focus";
import { PersonRenderState } from "./types";

interface PersonRenderData {
  nodeRenderData: NodeRenderData;
  centerX: number;
  person: Person;
  parent?: NodeRenderData;
}

interface NodeRenderData {
  node: TreeNode;
  visible: boolean;
  children: PersonRenderData[];
  people: PersonRenderData[];
}

export function getTreeLayout(
  treeNodeByDocId: Map<string, TreeNode>,
  personDocId: string,
  config: TreeConfig
): Map<Person, PersonRenderState> {
  const map = createMapWithInitializedUnlinkedData(
    Array.from(treeNodeByDocId.values()),
    getVisibleNodeSet(treeNodeByDocId, personDocId),
    config
  );
  populateParentChildData(map);

  const personNode = treeNodeByDocId.get(personDocId)!;
  const parentNode = personNode.people.find(
    (person) => person.data.docId === personDocId
  )?.parent;
  layoutTree(parentNode ?? personNode, map, config);

  const layout = new Map(
    Array.from(map.values())
      .map((nd) => toPersonRenderState(nd, config))
      .flat()
  );

  const states = Array.from(layout.values());
  function forEachState(fn: (state: PersonRenderState) => void) {
    for (const s of states) fn(s);
  }

  let minX = Infinity;
  let minY = Infinity;
  forEachState((s) => {
    if (s.opacity > 0) {
      minX = Math.min(minX, s.x);
      minY = Math.min(minY, s.y);
    }
  });

  forEachState((s) => {
    s.x += -minX + config.nodeSize / 2;
    s.y += -minY + config.nodeSize / 2;
  });

  return layout;
}

function toPersonRenderState(
  data: NodeRenderData,
  config: TreeConfig
): [Person, PersonRenderState][] {
  return data.people.map((personData) => [
    personData.person,
    {
      x: personData.centerX,
      y: personData.nodeRenderData.node.depth * config.levelSpacing,
      opacity: personData.nodeRenderData.visible ? 1 : 0,
      person: personData.person,
      velocity: 0,
    },
  ]);
}

function createMapWithInitializedUnlinkedData(
  treeNodes: TreeNode[],
  visibleNodes: Set<TreeNode>,
  config: TreeConfig
): Map<TreeNode, NodeRenderData> {
  return new Map(
    treeNodes.map((node) => {
      const left = getInitialLeft(node.people.length, config);
      const nodeRenderData: NodeRenderData = {
        node,
        people: [],
        children: [],
        visible: visibleNodes.has(node),
      };
      let nextX = left + config.nodeSize / 2;
      const sortedPeople =
        node.people.length === 2 &&
        (!node.people[0].parent || !visibleNodes.has(node.people[0].parent)) &&
        visibleNodes.has(node.people[1].parent!)
          ? [node.people[1], node.people[0]]
          : node.people;
      for (const person of sortedPeople) {
        nodeRenderData.people.push({ nodeRenderData, centerX: nextX, person });
        nextX += config.nodeSize + config.spouseSpacing;
      }
      return [node, nodeRenderData];
    })
  );
}

function populateParentChildData(renderDataMap: Map<TreeNode, NodeRenderData>) {
  for (const nodeRenderData of Array.from(renderDataMap.values())) {
    for (const child of nodeRenderData.node.children) {
      const childTreeNode = child.node;
      const childRenderData = renderDataMap.get(childTreeNode)!;

      for (const person of childTreeNode.people) {
        if (person.parent === nodeRenderData.node) {
          const renderDataPerson = childRenderData.people.find(
            (p) => p.person === person
          )!;
          nodeRenderData.children.push(renderDataPerson);
          renderDataPerson.parent = nodeRenderData;
        }
      }
    }
  }
}

function layoutTree(
  focusNode: TreeNode,
  renderDataMap: Map<TreeNode, NodeRenderData>,
  config: TreeConfig
) {
  layoutDescendentSubtree(
    getRenderDataForSubtree("down", focusNode, renderDataMap),
    config
  );
  layoutAncestorSubtree(
    getRenderDataForSubtree("up", focusNode, renderDataMap),
    config
  );
  layoutSiblings(focusNode, renderDataMap, config);
}

function getRenderDataForSubtree(
  dir: "up" | "down",
  root: TreeNode,
  fullRenderDataMap: Map<TreeNode, NodeRenderData>
): NodeRenderData[] {
  const focusNodeSubtreeSet = new Set([
    root,
    ...(dir === "down" ? getDescendents(root) : getAncestors(root)),
  ]);
  return Array.from(fullRenderDataMap.entries())
    .filter(([node]) => focusNodeSubtreeSet.has(node))
    .map(([_, data]) => data);
}

function layoutDescendentSubtree(
  singleRootRenderData: NodeRenderData[],
  config: TreeConfig
) {
  const getDownTreeMargin = getMargin.bind({}, "down");

  // Iterate through from the bottom up.
  const sortedRenderData = singleRootRenderData.sort(
    (a, b) => b.node.depth - a.node.depth
  );

  const root = sortedRenderData[sortedRenderData.length - 1];
  const originalRootCenterX = getCenterX(root);
  shiftSubtree("down", root, -originalRootCenterX);

  for (const nodeRenderData of sortedRenderData) {
    const visibleChildren = nodeRenderData.children
      .sort(ageCmp)
      .filter((child) => child.nodeRenderData.visible);
    const numChildren = visibleChildren.length;
    if (numChildren === 0) {
      continue;
    }

    let childrenWidth = Math.max(0, (numChildren - 1) * config.siblingMargin);
    for (let i = 0; i < numChildren; i++) {
      const isFirst = i === 0;
      const isLast = i === numChildren - 1;
      const child = visibleChildren[i];
      childrenWidth +=
        (isLast ? 0 : getDownTreeMargin(child, "right", config)) +
        (isFirst ? 0 : getDownTreeMargin(child, "left", config));
    }

    let childCenterX = -childrenWidth / 2;
    for (let i = 0; i < numChildren; i++) {
      const child = visibleChildren[i];
      shiftSubtree("both", child.nodeRenderData, childCenterX - child.centerX, [
        nodeRenderData,
      ]);
      childCenterX +=
        getDownTreeMargin(child, "right", config) +
        config.siblingMargin +
        (i < numChildren - 1
          ? getDownTreeMargin(visibleChildren[i + 1], "left", config)
          : 0);
    }
  }

  // We've balanced the tree under the root to be centered at 0, so shift it so
  // it's centered under the root.
  shiftSubtree("down", root, originalRootCenterX);
}

function layoutAncestorSubtree(
  singleRootRenderData: NodeRenderData[],
  config: TreeConfig
) {
  // Iterate through from the top down.
  for (const renderData of singleRootRenderData.sort(
    (a, b) => a.node.depth - b.node.depth
  )) {
    if (renderData.people.length < 2) continue;

    const leftIndex =
      renderData.people[0].centerX < renderData.people[1].centerX ? 0 : 1;
    const rightIndex = 1 - leftIndex;

    const leftParents = getLeftParents(renderData);
    if (leftParents) {
      const leftNodeRightEdge =
        renderData.people[leftIndex].centerX + config.nodeSize / 2;
      shiftSubtree(
        "both",
        leftParents,
        leftNodeRightEdge -
          calculateTreeExtents("up", leftParents, config).right,
        [renderData]
      );
    }

    const rightParents = getRightParents(renderData);
    if (rightParents) {
      const rightNodeLeftEdge =
        renderData.people[rightIndex].centerX - config.nodeSize / 2;
      shiftSubtree(
        "both",
        rightParents,
        rightNodeLeftEdge -
          calculateTreeExtents("up", rightParents, config).left,
        [renderData]
      );
    }
  }
}

function layoutSiblings(
  node: TreeNode,
  renderDataMap: Map<TreeNode, NodeRenderData>,
  config: TreeConfig
) {
  const data = renderDataMap.get(node)!;

  if (data.people.length < 2) {
    return;
  }

  const leftParents = getLeftParents(data);
  const rightParents = getRightParents(data);
  if (leftParents === null && rightParents === null) {
    return;
  }

  const leftSiblings =
    leftParents?.children
      .sort(ageCmp)
      .filter((sibling) => sibling.nodeRenderData !== data) ?? [];
  const rightSiblings =
    rightParents?.children
      .sort(ageCmp)
      .filter((sibling) => sibling.nodeRenderData !== data) ?? [];

  for (const sibling of [...leftSiblings, ...rightSiblings]) {
    layoutDescendentSubtree(
      getRenderDataForSubtree(
        "down",
        sibling.nodeRenderData.node,
        renderDataMap
      ),
      config
    );
  }

  if (leftSiblings.length) {
    const leftCenterX = Math.min(
      data.people[0].centerX,
      data.people[1].centerX
    );
    let leftMostCenterX = leftCenterX;

    let rightX =
      calculateTreeExtents("down", data, config).left - config.siblingMargin;
    for (const sibling of leftSiblings.reverse()) {
      shiftSubtree(
        "down",
        sibling.nodeRenderData,
        rightX -
          calculateTreeExtents("down", sibling.nodeRenderData, config).right
      );
      rightX =
        calculateTreeExtents("down", sibling.nodeRenderData, config).left -
        config.siblingMargin;
      leftMostCenterX = getCenterX(sibling.nodeRenderData);
    }
    const curParentsCenterX = getCenterX(leftParents!);
    const newParentsCenterX = Math.min(
      curParentsCenterX,
      (leftMostCenterX + leftCenterX) / 2
    );
    shiftSubtree("up", leftParents!, newParentsCenterX - curParentsCenterX);
  }

  if (rightSiblings.length) {
    const rightCenterX = Math.max(
      data.people[0].centerX,
      data.people[1].centerX
    );
    let rightMostCenterX = rightCenterX;

    let leftX =
      calculateTreeExtents("down", data, config).right + config.siblingMargin;
    for (const sibling of rightSiblings) {
      shiftSubtree(
        "down",
        sibling.nodeRenderData,
        leftX -
          calculateTreeExtents("down", sibling.nodeRenderData, config).left
      );
      leftX =
        calculateTreeExtents("down", sibling.nodeRenderData, config).right +
        config.siblingMargin;
      rightMostCenterX = getCenterX(sibling.nodeRenderData);
    }
    const curParentsCenterX = getCenterX(rightParents!);
    const newParentsCenterX = Math.max(
      curParentsCenterX,
      (rightMostCenterX + rightCenterX) / 2
    );
    shiftSubtree("up", rightParents!, newParentsCenterX - curParentsCenterX);
  }
}

function getMargin(
  dir: "up" | "down",
  child: PersonRenderData,
  side: "left" | "right",
  config: TreeConfig
): number {
  const childTreeXRange = calculateTreeExtents(
    dir,
    child.nodeRenderData,
    config
  );
  return side === "left"
    ? child.centerX - childTreeXRange.left
    : childTreeXRange.right - child.centerX;
}

function shiftSubtree(
  dir: "up" | "down" | "both",
  node: NodeRenderData,
  deltaX: number,
  excludeNodes: NodeRenderData[] = []
) {
  for (const toShift of getSubtree(dir, node, excludeNodes)) {
    shiftNode(toShift, deltaX);
  }
}

function getSubtree(
  dir: "up" | "down" | "both",
  node: NodeRenderData,
  excludeNodes: NodeRenderData[] = []
): NodeRenderData[] {
  const nodes: NodeRenderData[] = excludeNodes.includes(node) ? [] : [node];
  if (dir === "up" || dir === "both") {
    nodes.push(
      ...getParents(node)
        .filter((parent) => !excludeNodes.includes(parent))
        .map((parent) => getSubtree(dir, parent, [node, ...excludeNodes]))
        .flat()
    );
  }
  if (dir === "down" || dir === "both") {
    nodes.push(
      ...node.children
        .map((child) => child.nodeRenderData)
        .filter((child) => !excludeNodes.includes(child))
        .map((child) => getSubtree(dir, child, [node, ...excludeNodes]))
        .flat()
    );
  }
  return nodes;
}

function shiftNode(node: NodeRenderData, deltaX: number) {
  for (const p of node.people) {
    p.centerX += deltaX;
  }
}

function getLeftParents(node: NodeRenderData) {
  const leftIndex = node.people[0].centerX < node.people[1].centerX ? 0 : 1;
  return node.people[leftIndex].parent;
}

function getRightParents(node: NodeRenderData) {
  const rightIndex = node.people[0].centerX > node.people[1].centerX ? 0 : 1;
  return node.people[rightIndex].parent;
}

function calculateTreeExtents(
  dir: "up" | "down",
  renderData: NodeRenderData,
  config: TreeConfig
): { left: number; right: number } {
  const centerXes = getSubtree(dir, renderData)
    .filter((renderData) => renderData.visible)
    .map((renderData) => renderData.people.map((person) => person.centerX))
    .flat();
  return {
    left: min(centerXes) - config.nodeSize / 2,
    right: max(centerXes) + config.nodeSize / 2,
  };
}

function getInitialLeft(numPeople: number, config: TreeConfig) {
  const nodeRenderWidth =
    numPeople * config.nodeSize +
    Math.max(0, (numPeople - 1) * config.spouseSpacing);
  return -nodeRenderWidth / 2;
}

function getParents(node: NodeRenderData): NodeRenderData[] {
  return truthyList(node.people.map((person) => person.parent));
}

function getCenterX(node: NodeRenderData) {
  return average(node.people.map((person) => person.centerX));
}

function ageCmp(a: PersonRenderData, b: PersonRenderData) {
  if (!a.person.data.birthday && !b.person.data.birthday) {
    return 0;
  }
  if (!a.person.data.birthday) {
    return 1;
  }
  if (!b.person.data.birthday) {
    return -1;
  }
  return a.person.data.birthday.toMillis() - b.person.data.birthday.toMillis();
}
