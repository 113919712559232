import { TaggedPerson } from "../../firebase/images/types";

export function getPeopleWithFaceBounds(
  people: TaggedPerson[]
): TaggedPerson[] {
  return people.filter((person) => person.faceBounds);
}

export function getTaggedPersonById(
  taggedPeople: TaggedPerson[],
  docId: string
) {
  return taggedPeople.find((person) => person.personDocId === docId);
}

export function excludeTaggedPersonById(
  taggedPeople: TaggedPerson[],
  docId?: string
) {
  return taggedPeople.filter((person) => person.personDocId !== docId);
}
