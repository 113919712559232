export function truthyList<T>(orFalsy: (T | null | undefined)[]): T[] {
  return orFalsy.filter(isTruthy) as T[];
}

const isTruthy: (a: unknown) => boolean = (a: unknown) => !!a;

export function average(numbers: number[]): number {
  return numbers.reduce((prev, curr) => prev + curr) / numbers.length;
}

export function min(numbers: number[]): number {
  return numbers.reduce((prev, curr) => Math.min(prev, curr), Infinity);
}

export function max(numbers: number[]): number {
  return numbers.reduce((prev, curr) => Math.max(prev, curr), -Infinity);
}

export function erase<T>(list: T[], val: T) {
  const index = list.indexOf(val);
  if (index >= 0) {
    list.splice(index, 1);
  }
}

export function concatUniqueList<T>(list: T[] | undefined, val: T): T[] {
  if (!list?.length) {
    return [val];
  }
  return [...Array.from(new Set(list).add(val))];
}
