import { CSSProperties } from "react";
import { Firebase } from "../../../firebase/firebase";
import { ImageDocData, StoredImageData } from "../../../firebase/images/types";
import { StoredImage } from "../storedimage/StoredImage";

export interface FaceImageProps {
  imageDocData: ImageDocData;
  personDocId: string;
  dim: number;
  classes?: string;
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onLoad?: () => void;
}

export const FaceImage = (props: FaceImageProps) => {
  const taggedPerson = props.imageDocData.taggedPeople.find(
    (p) => p.personDocId === props.personDocId
  );
  const faceBounds = taggedPerson?.faceBounds;
  if (!faceBounds) {
    return null;
  }

  // Use the thumbnail as long as the face is at least half the size of the dimensions.
  const storedImage = getImageDataForDim(
    props.imageDocData,
    props.personDocId,
    props.dim
  );

  const imageScale = props.dim / (storedImage.width * faceBounds.width);

  const left = storedImage.width * faceBounds.left * imageScale;
  const top = storedImage.height * faceBounds.top * imageScale;

  return (
    <div
      style={{
        ...(props.style ?? {}),
        height: props.dim,
        width: props.dim,
        overflow: "hidden",
      }}
      className={props.classes ?? ""}
    >
      <StoredImage
        storedImage={storedImage}
        altText={Firebase.people.getLocal(props.personDocId)!.name}
        onClick={props.onClick}
        onLoad={props.onLoad}
        style={{
          height: `${storedImage.height * imageScale}px`,
          transform: `translate(${left * -1}px, ${top * -1}px)`,
          width: `${storedImage.width * imageScale}px`,
        }}
      />
    </div>
  );
};

export function getImageDataForDim(
  imageDocData: ImageDocData,
  personDocId: string,
  dim: number
): StoredImageData {
  const taggedPerson = imageDocData.taggedPeople.find(
    (p) => p.personDocId === personDocId
  );
  const faceBounds = taggedPerson?.faceBounds;
  if (!faceBounds) {
    // This is an error.
    return imageDocData.thumbnailImage;
  }
  return imageDocData.thumbnailImage.width * faceBounds.width >= dim
    ? imageDocData.thumbnailImage
    : imageDocData.highResImage;
}
