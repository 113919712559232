import React from "react";
import { StoredImageData } from "../../../firebase/images/types";

import { useStorageDownloadUrl } from "../../../firebase/storage/use_storage";
import "./StoredImage.scss";

export const StoredImage = (props: {
  storedImage: StoredImageData;
  altText: string;
  style?: React.CSSProperties;
  classes?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onLoad?: () => void;
}) => {
  const url = useStorageDownloadUrl(props.storedImage.fullPath);

  if (!url) {
    return null;
  }

  const classes =
    (props.onClick ? "StoredImageWithOnClick " : "") + props.classes;
  return (
    <img
      src={url}
      className={classes}
      alt={props.altText}
      onClick={props.onClick}
      style={props.style}
      onLoad={props.onLoad}
    />
  );
};
