import { Doc, getDocList } from "@jishikura/firebase-react";
import { getAuth } from "firebase/auth";
import { Firebase } from "../../../firebase/firebase";
import { PersonDoc, PersonDocData } from "../../../firebase/people/people";
import { usePersonMap } from "../../../firebase/people/use_person";
import { getPersonRelationMap } from "../../../utils/people/memoized";
import {
  Relation,
  isChild,
  isImmediateFamily,
  isSpouse,
} from "../../../utils/people/relation";
import {
  showConfirmDialog,
  showPromptDialog,
} from "../../dialoglayer/dialog_api";
import { useUserData } from "../../user/user";
import { AnchorButton } from "../../widgets/anchorbutton/AnchorButton";
import { setPage } from "../pageapi/page";
import "./HomePage.scss";
import { ListPeopleEntry } from "./ListPeopleEntry";

export const HomePage = () => {
  const personMap = usePersonMap();
  const userData = useUserData();

  if (!personMap || !userData?.userDoc) {
    return null;
  }

  const personDocList = getDocList(personMap);
  if (personDocList.length === 0) {
    return null;
  }

  const personDocId = userData.userDoc.personDocId!;

  const personRelationMap = getPersonRelationMap(personDocId, personMap);

  const self: PersonDoc[] = [];
  const mainFamily: PersonDoc[] = [];
  const immediateFamily: PersonDoc[] = [];
  const directRelatives: PersonDoc[] = [];
  const bloodRelatives: PersonDoc[] = [];
  const inLaws: PersonDoc[] = [];
  const noRelation: PersonDoc[] = [];
  for (const personDoc of personDocList) {
    const relation = personRelationMap.get(personDoc.docId);
    if (personDoc.docId === personDocId) {
      self.push(personDoc);
    } else if (!relation) {
      noRelation.push(personDoc);
    } else if (isChild(relation) || isSpouse(relation)) {
      mainFamily.push(personDoc);
    } else if (isImmediateFamily(relation)) {
      immediateFamily.push(personDoc);
    } else if (
      relation.byBlood &&
      (relation.nearestCommonAncestorDepthDelta === 0 ||
        relation.nearestCommonAncestorDepthDelta === relation.depthDelta)
    ) {
      directRelatives.push(personDoc);
    } else if (relation.byBlood) {
      bloodRelatives.push(personDoc);
    } else {
      inLaws.push(personDoc);
    }
  }

  return (
    <div className='ListPeople'>
      <ListPeopleEntry
        docId={self[0].docId}
        data={self[0]}
        profilePicDim={64}
        relation={personRelationMap.get(self[0].docId)}
        className='ListPeopleMainEntry'
      />
      {section(mainFamily, 48, personRelationMap)}
      {section(immediateFamily, 48, personRelationMap)}
      {section(directRelatives, 48, personRelationMap)}
      {section(bloodRelatives, 0, personRelationMap)}
      {section(inLaws, 0, personRelationMap)}
      {section(noRelation, 0, personRelationMap)}
      <div className='ListPeopleAddPersonContainer'>
        <NewPersonEntry />
      </div>
      <div className='ListPeopleWrongPersonContainer'>
        <AnchorButton
          onClick={async () => {
            showConfirmDialog(
              `Are you sure you're not ${self[0].name}?`,
              async () => {
                await Firebase.users.update(getAuth().currentUser!.uid, {
                  personDocId: "",
                });
              }
            );
          }}
        >
          I'm not {self[0].name}.
        </AnchorButton>
      </div>
    </div>
  );
};

function section(
  personDocList: Doc<PersonDocData>[],
  profilePicDim: number,
  personRelationMap: Map<string, Relation | undefined>
) {
  const validPeople = personDocList.filter((person) => person.name);
  if (validPeople.length === 0) return null;
  return (
    <div className='ListPeopleSection' key={validPeople[0].docId}>
      {validPeople.map((person) => (
        <ListPeopleEntry
          docId={person.docId}
          data={person}
          relation={personRelationMap.get(person.docId)}
          profilePicDim={profilePicDim}
          key={person.docId}
        />
      ))}
    </div>
  );
}

const NewPersonEntry = () => {
  async function onClick() {
    showPromptDialog("What is this person's name?", async (name: string) => {
      const docRef = await Firebase.people.add({ name });
      setPage({ kind: "person", personDocId: docRef.id });
    });
  }

  return (
    <div>
      <button onClick={() => onClick()}>Add person</button>
    </div>
  );
};
