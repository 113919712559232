import { PhotoGallery } from "../../widgets/photogallery/PhotoGallery";

import { useState } from "react";
import { usePersonDocData } from "../../../firebase/people/use_person";
import { showImageAdder } from "../../imagelayer/image_layer_api";
import { AnchorButton } from "../../widgets/anchorbutton/AnchorButton";
import { PersonWiki } from "../../widgets/personwiki/PersonWiki";
import { setPage } from "../pageapi/page";
import "./PersonPage.scss";
import { FullscreenFamilyTreeOverlay } from "./fullscreenfamilytree/FullscreenFamilyTree";
import { ThumbnailFamilyTree } from "./thumbnailfamilytree/ThumbnailFamilyTree";

export const PersonPage = (props: {
  docId: string;
  hideEditButton?: boolean;
}) => {
  const [showingFullscreenFamilyTree, setShowingFullscreenFamilyTree] =
    useState(false);
  const personDocData = usePersonDocData(props.docId);

  if (!personDocData) {
    return null;
  }

  return (
    <div className='PersonPage'>
      <div className='PersonPage-TopBar'>
        <AnchorButton onClick={() => void setPage({ kind: "home" })}>
          Everyone
        </AnchorButton>
      </div>
      {/* Name */}
      <div className='PersonPage-Name'>{personDocData.name}</div>
      {/* Wiki */}
      <PersonWiki docId={props.docId} docData={personDocData} />
      {/* ThumbnailFamilyTree */}
      <div
        className='PersonPage-ThumbnailFamilyTreeContainer'
        onClick={() => setShowingFullscreenFamilyTree(true)}
      >
        <ThumbnailFamilyTree focusPersonDocId={props.docId} key={props.docId} />
      </div>
      {/* PhotoGallery */}
      <div className='PersonPage-PhotoGallery'>
        {personDocData.imageDocIds?.length ? (
          <PhotoGallery imageDocIds={personDocData.imageDocIds} />
        ) : (
          <div className='PersonPage-PhotoGallery-NoPhotos'>
            There are no photos of {personDocData.name}.
          </div>
        )}
        <div className='PersonPage-PhotoGallery-AddPhoto'>
          Click&nbsp;
          <AnchorButton
            onClick={() => showImageAdder({ sourcePersonDocId: props.docId })}
          >
            here
          </AnchorButton>{" "}
          to add a photo.
        </div>
      </div>
      {/* ButtonContainer */}
      <div className='PersonPage-ButtonContainer'>
        <button
          onClick={() =>
            setPage({ kind: "editPerson", personDocId: props.docId })
          }
        >
          Edit this page
        </button>
      </div>
      {showingFullscreenFamilyTree && (
        <FullscreenFamilyTreeOverlay
          personDocId={props.docId}
          dismiss={() => setShowingFullscreenFamilyTree(false)}
        />
      )}
    </div>
  );
};
