import { usePersonMap } from "../../../../firebase/people/use_person";
import { getTreeNodeByDocIdMap } from "../../../../utils/people/memoized";
import { createPersonRelationMap } from "../../../../utils/people/relation";
import "./FamilyTree.scss";
import {
  FamilyTreeCoupleConnection,
  FamilyTreeToParentConnection,
} from "./connections/FamilyTreeConnections";
import { TreeConfig } from "./render_config";
import { getTreeLayout } from "./utils/layout";
import { PersonRenderState } from "./utils/types";

export const FamilyTree = (props: {
  personDocId: string;
  treeConfig: TreeConfig;
  personRenderer: (props: {
    personRenderState: PersonRenderState;
    config: TreeConfig;
    isSelected?: boolean;
  }) => JSX.Element;
  focusBackground?: (props: {
    personRenderState: PersonRenderState;
  }) => JSX.Element;
  className?: string;
}) => {
  const personMap = usePersonMap();

  if (!personMap) {
    return null;
  }

  const treeNodeByDocIdMap = getTreeNodeByDocIdMap(personMap);

  const { treeConfig } = props;
  const treeNodes = Array.from(treeNodeByDocIdMap.values());
  const layout = getTreeLayout(
    treeNodeByDocIdMap,
    props.personDocId,
    treeConfig
  );

  const states = Array.from(layout.values());

  const toParentConnections = states
    .filter((personRenderState) => personRenderState.person.parent)
    .map((personRenderState) => {
      const docId = personRenderState.person.data.docId;
      return (
        <FamilyTreeToParentConnection
          personRenderState={personRenderState}
          parentRenderState={
            personRenderState.person.parent?.people.map(
              (p) => layout.get(p)!
            ) ?? []
          }
          config={treeConfig}
          key={docId}
        />
      );
    });

  const coupleConnections = [...Array.from(new Set(treeNodes))]
    .filter((node) => node.people.length > 1)
    .map((node) => (
      <FamilyTreeCoupleConnection
        peopleRenderState={node.people.map((p) => layout.get(p)!)}
        childrenRenderState={node.children.map((p) => layout.get(p)!)}
        config={treeConfig}
        key={node.people[0].data.docId + "-" + node.people[1].data.docId}
      />
    ));

  let focusPersonRenderState: PersonRenderState | undefined = undefined;

  let maxVisibleX = -Infinity;
  let maxVisibleY = -Infinity;
  const people = states
    .filter((p) => p.opacity > 0)
    .map((personRenderState) => {
      const docId = personRenderState.person.data.docId;

      maxVisibleX = Math.max(maxVisibleX, personRenderState.x);
      maxVisibleY = Math.max(maxVisibleY, personRenderState.y);

      if (docId === props.personDocId) {
        focusPersonRenderState = personRenderState;
      }

      return (
        <props.personRenderer
          key={docId}
          personRenderState={personRenderState}
          config={treeConfig}
          isSelected={props.personDocId === docId}
        />
      );
    });

  const personRelationMap = createPersonRelationMap(
    props.personDocId,
    treeNodeByDocIdMap
  );
  let minDepth = 0;
  let maxDepth = 0;
  for (const state of states) {
    if (state.opacity > 0) {
      const depthDelta =
        personRelationMap.get(state.person.data.docId)?.depthDelta ?? 0;
      minDepth = Math.min(depthDelta, minDepth);
      maxDepth = Math.max(depthDelta, maxDepth);
    }
  }

  const horizontalMargin = treeConfig.outerMargin?.horizontal ?? 0;
  const verticalMargin = treeConfig.outerMargin?.vertical ?? 0;
  return (
    <div
      className={`FamilyTreeTreeContainer ${props.className ?? ""}`}
      style={{
        height: `${maxVisibleY + treeConfig.nodeSize / 2 + verticalMargin}px`,
        left: `${horizontalMargin}px`,
        top: `${verticalMargin}px`,
        width: `${maxVisibleX + treeConfig.nodeSize / 2 + horizontalMargin}px`,
      }}
    >
      {focusPersonRenderState && props.focusBackground && (
        <props.focusBackground personRenderState={focusPersonRenderState} />
      )}
      {toParentConnections}
      {coupleConnections}
      {people}
    </div>
  );
};
