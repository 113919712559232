import { Timestamp } from "firebase/firestore/lite";
import { ChangeEvent } from "react";

export const DatePicker = (props: {
  value?: Timestamp;
  onChange: (date: Timestamp) => void;
  className?: string;
}) => {
  return (
    <input
      type='date'
      className={props.className}
      value={props.value?.toDate().toISOString().substring(0, 10)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const inputEl = e.target as HTMLInputElement;
        const date = new Date(inputEl.value);
        if (Number.isNaN(date.valueOf())) {
          return;
        }
        // Pick noon in the current timezone. getTimezoneOffset is the offset, not for the target date, so this will sometimes not quite be noon, but we don't care about the time, so it's good enough.
        date.setTime(date.getTime() + (12 + date.getTimezoneOffset()) * 60000);
        props.onChange(Timestamp.fromDate(date));
      }}
    />
  );
};
