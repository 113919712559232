import { useState } from "react";
import { usePersonDocData } from "../../../firebase/people/use_person";
import {
  dismissDialog,
  showAlertDialog,
  showConfirmDialog,
  showLoadingDialog,
} from "../../dialoglayer/dialog_api";
import { AnchorButton } from "../../widgets/anchorbutton/AnchorButton";
import { hideImageLayer, showImageViewer } from "../image_layer_api";
import "./ImageAdder.scss";
import { ImageAdder as ImageAdderInternal } from "./image_adder";

export const ImageAdder = (props: { sourcePersonDocId: string }) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [hadError, setHadError] = useState(false);

  const adder = file ? new ImageAdderInternal(file) : undefined;

  return (
    <div className='ImageAdder'>
      <div className='ImageAdder-Title'>Add a photo</div>
      {!adder && <ImagePicker setFile={setFile} hadError={hadError} />}
      {adder && (
        <ImagePreview
          adder={adder}
          sourcePersonDocId={props.sourcePersonDocId}
          onError={() => setHadError(true)}
          onCancel={() => setFile(undefined)}
        />
      )}
      {!file && (
        <div className='ImageAdder-ButtonContainer'>
          <button onClick={() => hideImageLayer()}>Cancel</button>
        </div>
      )}
    </div>
  );
};

const ImagePicker = (props: {
  setFile: (file: File) => void;
  hadError: boolean;
}) => {
  return (
    <div className='ImageAdder-ImagePicker'>
      {props.hadError && (
        <div>Something went wrong. Please try a different image.</div>
      )}
      <div>Upload a photo by choosing a file below.</div>
      <div className='ImageAdder-ImagePicker-FileInputContainer'>
        <input
          type='file'
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              props.setFile(file);
            }
          }}
        />
      </div>
    </div>
  );
};

const ImagePreview = (props: {
  adder: ImageAdderInternal;
  sourcePersonDocId: string;
  onError: () => void;
  onCancel: () => void;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const sourcePerson = usePersonDocData(props.sourcePersonDocId);

  if (!sourcePerson) {
    hideImageLayer();
    return null;
  }

  props.adder
    .waitForPreviewReady()
    .then(() => setIsLoaded(true))
    .catch(() => props.onError());

  const tryAddNewImage = () => {
    showConfirmDialog("Are you sure you want to add this photo?", async () => {
      try {
        showLoadingDialog();
        const imageDoc = await props.adder.commit(props.sourcePersonDocId);
        dismissDialog();
        showImageViewer({ imageDocIds: [imageDoc.id] });
      } catch (e: unknown) {
        showAlertDialog(
          `Something went wrong (${e}).\nPlease try a different photo.`
        );
      }
    });
  };

  return (
    <div className='ImageAdder-ImagePreview'>
      <img
        src={props.adder.getPreviewImageSrc()}
        alt='Preview'
        className='ImageAdder-ImagePreview-Image'
      />
      <div className='ImageAdder-ImagePreview-Caption'>
        {props.adder.file.name}
      </div>
      <div>Would you like to add this photo?</div>
      <div className='ImageAdder-ImagePreview-ButtonContainer'>
        {isLoaded && <button onClick={tryAddNewImage}>Add this photo</button>}
        <AnchorButton
          onClick={props.onCancel}
          className='ImageAdder-ImagePreview-ButtonContainer-CancelButton'
        >
          Cancel
        </AnchorButton>
      </div>
    </div>
  );
};
