import { FirebaseError } from "firebase/app";

import "./ErrorPage.scss";

export const ErrorPage = (props: { e: unknown }) => {
  return (
    <div className='ErrorContainer'>
      <div className='ErrorContent'>
        {props.e instanceof FirebaseError
          ? firebaseError(props.e)
          : unknownError(props.e)}
      </div>
    </div>
  );
};

function firebaseError(e: FirebaseError) {
  return e.code === "permission-denied"
    ? permissionDeniedError()
    : unknownError(e);
}

function permissionDeniedError() {
  return (
    <div>
      <div>You do not have permission to access this page.</div>
      <div>
        Request permission from the administrator or login as a different user.
      </div>
    </div>
  );
}

function unknownError(e: unknown) {
  return (
    <div>
      <div>Something went wrong. Try again later.</div>
      <div>{e ? `${e}` : ""}</div>
    </div>
  );
}
