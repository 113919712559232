import ReactMarkdown from "react-markdown";
import { PersonDocData } from "../../../firebase/people/people";
import "./PersonWiki.scss";
import { InfoBox } from "./infobox/InfoBox";

export const PersonWiki = (props: {
  docId: string;
  docData: PersonDocData;
  classes?: string;
}) => {
  const maybeArticle = props.docData.article;
  return (
    <div className={`PersonWiki ${props.classes ?? ""}`}>
      <div
        className='PersonWiki-InfoBoxContainer'
        style={
          (maybeArticle && {
            float: "right",
          }) || {
            marginLeft: "auto",
            marginRight: "auto",
          }
        }
      >
        <InfoBox docId={props.docId} docData={props.docData} />
      </div>
      <ReactMarkdown>{maybeArticle ?? ""}</ReactMarkdown>
    </div>
  );
};
