import React, { useState } from "react";
import { Firebase } from "../../../firebase/firebase";
import { ImageDocData, TaggedPerson } from "../../../firebase/images/types";
import { hideImageLayer, startEditingImage } from "../image_layer_api";

import { useImageDocData } from "../../../firebase/images/use_images";
import { PersonDoc } from "../../../firebase/people/people";
import { getAspectRatio } from "../../../utils/image";
import {
  excludeTaggedPersonById,
  getPeopleWithFaceBounds,
  getTaggedPersonById,
} from "../../../utils/people/tagged_people";
import { setPage } from "../../body/pageapi/page";
import { AnchorButton } from "../../widgets/anchorbutton/AnchorButton";
import { StoredImage } from "../../widgets/storedimage/StoredImage";
import { FaceBounds, getFaceBoundsPersonDocId } from "../shared/FaceBounds";
import { FaceLabel } from "../shared/FaceLabel";
import "./SingleImageViewer.scss";

export const SingleImageViewer = (props: {
  docId: string;
  index: number;
  isCenter: boolean;
  containerWidth: number;
  containerHeight: number;
}) => {
  const imageDocData = useImageDocData(props.docId);
  const [showInfo, setShowInfo] = useState(false);
  const [highlightedPersonDocId, setHighlightedPersonDocId] = useState<
    string | undefined
  >();
  const [highResLoaded, setHighResLoaded] = useState(false);

  if (!imageDocData) {
    return null;
  }

  const handleImageClick = (e: React.MouseEvent) => {
    const clickedPersonDocId = getFaceBoundsPersonDocId(e.target);
    if (clickedPersonDocId && clickedPersonDocId !== highlightedPersonDocId) {
      setHighlightedPersonDocId(clickedPersonDocId);
      e.stopPropagation();
    } else if (highlightedPersonDocId) {
      setHighlightedPersonDocId(undefined);
      e.stopPropagation();
    } else {
      setShowInfo(!showInfo);
    }
  };

  const people = getPeopleWithFaceBounds(imageDocData.taggedPeople);
  const aspectRatio = getAspectRatio(imageDocData);

  let invisiblePeople: TaggedPerson[] = [];
  let highlightPeople: TaggedPerson[] = [];

  if (highlightedPersonDocId === undefined) {
    highlightPeople = people;
  } else {
    invisiblePeople = excludeTaggedPersonById(people, highlightedPersonDocId);
    highlightPeople = [getTaggedPersonById(people, highlightedPersonDocId)!];
  }

  const scale = Math.min(
    props.containerWidth / imageDocData.highResImage.width,
    props.containerHeight / imageDocData.highResImage.height
  );

  const canLoadHighRes = props.isCenter;

  return (
    <div
      className='SingleImageViewer'
      id='sivbg'
      onClick={(e: React.MouseEvent) => {
        if (e.target instanceof HTMLDivElement && e.target.id === "sivbg") {
          hideImageLayer();
        }
      }}
    >
      <div
        className='SingleImageViewer-ImageWithOverlays'
        onClick={(e) => handleImageClick(e)}
        style={{
          height: `${imageDocData.highResImage.height * scale}px`,
          width: `${imageDocData.highResImage.width * scale}px`,
        }}
      >
        {(!canLoadHighRes || !highResLoaded) && (
          <StoredImage
            storedImage={imageDocData.thumbnailImage}
            altText={imageDocData.caption ?? "Photo"}
            classes={"SingleImageViewer-ImageWithOverlays-Image"}
          />
        )}
        {canLoadHighRes && (
          <StoredImage
            storedImage={imageDocData.highResImage}
            altText={imageDocData.caption ?? "Photo"}
            classes={"SingleImageViewer-ImageWithOverlays-Image"}
            style={!highResLoaded ? { display: "none" } : undefined}
            onLoad={() => setHighResLoaded(true)}
          />
        )}
        {showInfo && (
          <div className='SingleImageViewer-ImageWithOverlays-Overlays'>
            {invisiblePeople.map((person) => (
              <FaceBounds
                aspectRatio={aspectRatio}
                person={person}
                key={person.personDocId}
              />
            ))}
            {highlightPeople.map((person) => (
              <FaceBounds
                aspectRatio={aspectRatio}
                person={person}
                classes='SingleImageViewer-ImageWithOverlays-Overlays-FaceBounds'
                key={person.personDocId}
              />
            ))}
            {highlightPeople.map((person) => (
              <FaceLabel
                aspectRatio={aspectRatio}
                person={person}
                classes='SingleImageViewer-ImageWithOverlays-Overlays-FaceLabel'
                key={"l" + person.personDocId}
              />
            ))}
          </div>
        )}
      </div>
      {showInfo && (
        <EditButton onClick={() => void startEditingImage(props.index)} />
      )}
      {showInfo && (
        <div className='SingleImageViewer-InfoFooter'>
          <CaptionAndDate imageDocData={imageDocData} />
          <TaggledPeople imageDocData={imageDocData} />
        </div>
      )}
    </div>
  );
};

const EditButton = (props: { onClick: () => void }) => {
  return (
    <div className='SingleImageViewer-EditButtonContainer'>
      <button
        onClick={props.onClick}
        className={`SingleImageViewer-EditButtonContainer-Button`}
      >
        Edit this image
      </button>
    </div>
  );
};

const CaptionAndDate = (props: { imageDocData: ImageDocData }) => {
  if (!props.imageDocData.caption && !props.imageDocData.date) {
    return null;
  }
  return (
    <div className='SingleImageViewer-InfoFooter-CaptionAndDate'>
      {props.imageDocData.caption}
      {props.imageDocData.date && (
        <span className='SingleImageViewer-InfoFooter-CaptionAndDate-Date'>
          {props.imageDocData.date.toDate().toLocaleDateString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
      )}
    </div>
  );
};

const TaggledPeople = (props: { imageDocData: ImageDocData }) => {
  const taggedPersonDocs = getTaggedPersonDocs(props.imageDocData.taggedPeople);
  if (taggedPersonDocs.length === 0) {
    return null;
  }

  return (
    <div className='SingleImageViewer-InfoFooter-TaggedPeople'>
      {taggedPersonDocs.map((personDoc, index) => (
        <AnchorButton
          onClick={(e) => {
            setPage({ kind: "person", personDocId: personDoc.docId });
            hideImageLayer();
            e.stopPropagation();
          }}
          key={personDoc.docId}
          className='SingleImageViewer-InfoFooter-TaggedPeople-Link'
        >
          {personDoc.name}
          {index < props.imageDocData.taggedPeople.length - 1 && ","}
        </AnchorButton>
      ))}
    </div>
  );
};

function getTaggedPersonDocs(taggedPeople: TaggedPerson[] | undefined) {
  return (taggedPeople ?? [])
    .map((taggedPerson) => Firebase.people.getLocal(taggedPerson.personDocId))
    .filter((maybePeople) => maybePeople) as PersonDoc[];
}
