import { SubscribableData } from "@jishikura/react-subscribable";
import { useSyncExternalStore } from "react";
import { getUrlHash, replaceUrlHash } from "../body/pageapi/url";

// ============================================================================

export function showImageViewer(data: {
  imageDocIds: string[];
  initialViewIndex?: number;
}) {
  IMAGE_LAYER_SPEC.set({
    kind: "imageViewer",
    imageDocIds: data.imageDocIds,
    initialViewIndex: data.initialViewIndex,
  });
  replaceUrlHash({
    imageLayerState: IMAGE_LAYER_STATE_URL_KEY.view,
    imageIndex: (data.initialViewIndex ?? 0) + "",
    imageDocIds: data.imageDocIds.join(" "),
  });
}

export function startEditingImage(imageIndex: number) {
  const spec = IMAGE_LAYER_SPEC.get();
  if (spec?.kind !== "imageViewer") {
    console.error("Cannot start editing");
    return;
  }

  IMAGE_LAYER_SPEC.set({
    kind: "imageEditor",
    imageDocIds: spec.imageDocIds,
    editIndex: imageIndex,
  });
  replaceUrlHash({
    imageLayerState: IMAGE_LAYER_STATE_URL_KEY.edit,
  });
}

export function showImageAdder(data: { sourcePersonDocId: string }) {
  IMAGE_LAYER_SPEC.set({
    kind: "imageAdder",
    sourcePersonDocId: data.sourcePersonDocId,
  });
  // No need to persist this state.
  replaceUrlHash({
    imageLayerState: null,
    imageIndex: null,
    imageDocIds: null,
  });
}

export function hideImageLayer() {
  IMAGE_LAYER_SPEC.set(/* data= */ undefined);
  replaceUrlHash({
    imageLayerState: null,
    imageIndex: null,
    imageDocIds: null,
  });
}

// ============================================================================

export function restoreImageLayerFromUrl() {
  const state = getUrlHash("imageLayerState");
  if (
    state === IMAGE_LAYER_STATE_URL_KEY.view ||
    state === IMAGE_LAYER_STATE_URL_KEY.edit
  ) {
    const index = Number(getUrlHash("imageIndex") ?? "0");
    const imageDocIds = getUrlHash("imageDocIds")?.split(" ") ?? [];
    if (index < 0 || index > imageDocIds.length - 1) {
      // Invalid url, clear the fragment and do not restore.
      replaceUrlHash({
        imageLayerState: null,
        imageIndex: null,
        imageDocIds: null,
      });
      hideImageLayer();
      return;
    }
    showImageViewer({ imageDocIds, initialViewIndex: index });
    if (state === IMAGE_LAYER_STATE_URL_KEY.edit) {
      startEditingImage(index);
    }
  } else {
    replaceUrlHash({
      imageLayerState: null,
      imageIndex: null,
      imageDocIds: null,
    });
    hideImageLayer();
  }
}

// ============================================================================

export interface ImageViewerSpec {
  kind: "imageViewer";
  imageDocIds: string[];
  initialViewIndex?: number;
}

export interface ImageEditorSpec {
  kind: "imageEditor";
  imageDocIds: string[];
  editIndex: number;
}

export interface ImageAdderSpec {
  kind: "imageAdder";
  sourcePersonDocId: string;
}

export type ImageLayerSpec = ImageViewerSpec | ImageEditorSpec | ImageAdderSpec;

export function useImageLayer() {
  return useSyncExternalStore(...IMAGE_LAYER_SPEC.sync());
}

// ============================================================================

export const IMAGE_LAYER_STATE_URL_KEY = {
  view: "v",
  edit: "e",
  add: "a",
};

const IMAGE_LAYER_SPEC = new SubscribableData<ImageLayerSpec | undefined>(
  undefined
);
