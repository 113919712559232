import { AlertDialog } from "./AlertDialog";
import { ConfirmDialog } from "./ConfirmDialog";
import "./Dialog.scss";
import { LoadingDialog } from "./LoadingDialog";
import { PromptDialog } from "./PromptDialog";
import { useDialogData } from "./dialog_api";

export const DialogLayer = (props: { zIndex: number }) => {
  const dialogData = useDialogData();

  if (dialogData === undefined) {
    return null;
  }

  return (
    <div
      className='Dialog-Container'
      style={{ zIndex: props.zIndex }}
      ref={(element) => {
        // Block scrolling.
        element?.addEventListener("scroll", (e) => e.preventDefault(), {
          passive: false,
        });
        element?.addEventListener("wheel", (e) => e.preventDefault(), {
          passive: false,
        });
      }}
    >
      {dialogData.kind === "alert" && (
        <AlertDialog message={dialogData.message} />
      )}
      {dialogData.kind === "confirm" && (
        <ConfirmDialog
          message={dialogData.message}
          onConfirm={dialogData.onConfirm}
        />
      )}
      {dialogData.kind === "loading" && <LoadingDialog />}
      {dialogData.kind === "prompt" && (
        <PromptDialog
          message={dialogData.message}
          onConfirm={dialogData.onConfirm}
          options={dialogData.options}
        />
      )}
    </div>
  );
};
