import { SubscribableData } from "@jishikura/react-subscribable";
import { User } from "firebase/auth";
import { useSyncExternalStore } from "react";
import { Firebase } from "../../firebase/firebase";
import { UserDoc } from "../../firebase/users/users";

// ============================================================================

export function useUserData() {
  return useSyncExternalStore(...UserDataManager.instance.state.sync());
}

// ============================================================================

export interface UserData {
  user?: User;
  userDoc?: UserDoc;
}

class UserDataManager {
  readonly state = new SubscribableData<UserData | undefined>(undefined);

  private unsubscribeCb?: () => void;

  private constructor() {
    Firebase.auth.onAuthStateChanged((user) => {
      if (!user) {
        this.unsubscribeCb?.();
        this.unsubscribeCb = undefined;

        this.state.set({});
      } else {
        const cachedUserDocData = Firebase.users.getLocal(user.uid);
        this.state.set({
          user,
          userDoc: { docId: user.uid, ...cachedUserDocData },
        });
        Firebase.users.get(user.uid);
        this.unsubscribeCb = Firebase.users.subscribe(
          user.uid,
          (userDocData) => {
            if (this.state.get()?.user === user) {
              this.state.set({
                user,
                userDoc:
                  userDocData !== null
                    ? {
                        docId: user.uid,
                        ...userDocData,
                      }
                    : undefined,
              });
            }
          }
        );
      }
    });
  }

  static instanceInternal?: UserDataManager;
  static get instance() {
    if (!this.instanceInternal) {
      this.instanceInternal = new UserDataManager();
    }
    return this.instanceInternal;
  }
}
