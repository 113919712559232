import { Gender } from "../../firebase/people/people";
import { getAncestors, TreeNode } from "./tree";

export interface Relation {
  byBlood: boolean;
  depthDelta: number;
  nearestCommonAncestorDepthDelta: number;
}

export function getRelation(
  fromDocId: string,
  toDocId: string,
  treeNodesByDocId: Map<string, TreeNode>,
  excludeInLaw?: boolean
): Relation | undefined {
  const fromNode = treeNodesByDocId.get(fromDocId)!;
  const toNode = treeNodesByDocId.get(toDocId)!;

  if (fromNode === toNode) {
    return {
      byBlood: fromDocId === toDocId,
      depthDelta: 0,
      nearestCommonAncestorDepthDelta: 0,
    };
  }

  const fromNodeAncestors = [fromNode, ...getAncestors(fromNode, fromDocId)];
  const toNodeAncestorSet = new Set([toNode, ...getAncestors(toNode, toDocId)]);

  let nearestCommonAncestorDepth = -Infinity;
  for (const fromNodeAncestor of fromNodeAncestors) {
    if (
      fromNodeAncestor.depth > nearestCommonAncestorDepth &&
      toNodeAncestorSet.has(fromNodeAncestor)
    ) {
      nearestCommonAncestorDepth = fromNodeAncestor.depth;
    }
  }

  if (nearestCommonAncestorDepth !== -Infinity) {
    const fromDepth = fromNode.depth;
    return {
      byBlood: true,
      depthDelta: toNode.depth - fromDepth,
      nearestCommonAncestorDepthDelta: nearestCommonAncestorDepth - fromDepth,
    };
  }

  if (!excludeInLaw) {
    // Check if they're my spouse's family by blood.
    if (fromNode.people.length > 1) {
      const spouseDocId = fromNode.people.find(
        (person) => person.data.docId !== fromDocId
      )!.data.docId;
      const relationToSpouse = getRelation(
        spouseDocId,
        toDocId,
        treeNodesByDocId,
        /* excludeInLaw= */ true
      );
      if (relationToSpouse) {
        return { ...relationToSpouse, byBlood: false };
      }
    }
    // Check if I'm related to their spouse.
    if (toNode.people.length > 1) {
      const spouseDocId = toNode.people.find(
        (person) => person.data.docId !== toDocId
      )!.data.docId;
      const relationToSpouse = getRelation(
        fromDocId,
        spouseDocId,
        treeNodesByDocId,
        /* excludeInLaw= */ true
      );
      if (relationToSpouse) {
        return { ...relationToSpouse, byBlood: false };
      }
    }
  }

  return undefined;
}

export function getRelationString(relation?: Relation, gender?: Gender) {
  if (!relation) {
    return "no relation";
  }
  if (relation.byBlood) {
    return getByBloodRelationString(relation, gender);
  } else {
    if (
      relation.depthDelta === 0 &&
      relation.nearestCommonAncestorDepthDelta === 0
    ) {
      return gendered(gender, "husband", "wife", "spouse");
    }
    return getByBloodRelationString(relation, gender) + "-in-law";
  }
}

export function isImmediateFamily(relation: Relation) {
  return isSibling(relation) || isParent(relation) || isChild(relation);
}

export function isSibling(relation: Relation) {
  return (
    relation.byBlood &&
    relation.depthDelta === 0 &&
    relation.nearestCommonAncestorDepthDelta === -1
  );
}

export function isParent(relation: Relation) {
  return (
    relation.byBlood &&
    relation.depthDelta === -1 &&
    relation.depthDelta === relation.nearestCommonAncestorDepthDelta
  );
}

export function isSpouse(relation: Relation) {
  return (
    !relation.byBlood &&
    relation.depthDelta === 0 &&
    relation.nearestCommonAncestorDepthDelta === 0
  );
}

export function isChild(relation: Relation) {
  return (
    relation.byBlood &&
    relation.depthDelta === 1 &&
    relation.nearestCommonAncestorDepthDelta === 0
  );
}

function getByBloodRelationString(
  relation: Omit<Relation, "byBlood">,
  gender?: Gender
): string {
  if (relation.depthDelta === 0) {
    if (relation.nearestCommonAncestorDepthDelta === 0) {
      return "self";
    } else if (relation.nearestCommonAncestorDepthDelta === -1) {
      return gendered(gender, "brother", "sister", "sibling");
    }
  } else if (relation.depthDelta < 0) {
    if (relation.depthDelta === relation.nearestCommonAncestorDepthDelta) {
      return (
        depthPrefix(relation.depthDelta) +
        gendered(gender, "father", "mother", "parent")
      );
    } else if (
      relation.depthDelta ===
      relation.nearestCommonAncestorDepthDelta + 1
    ) {
      return (
        depthPrefix(relation.depthDelta) + gendered(gender, "uncle", "aunt")
      );
    }
  } else if (relation.depthDelta > 0) {
    if (relation.nearestCommonAncestorDepthDelta === 0) {
      return (
        depthPrefix(relation.depthDelta) +
        gendered(gender, "son", "daughter", "child")
      );
    } else if (relation.nearestCommonAncestorDepthDelta === -1) {
      return (
        depthPrefix(relation.depthDelta) + gendered(gender, "nephew", "niece")
      );
    }
  }

  return (
    nth(
      Math.min(relation.depthDelta, 0) -
        relation.nearestCommonAncestorDepthDelta -
        1
    ) +
    " cousin" +
    (relation.depthDelta !== 0
      ? " " + times(Math.abs(relation.depthDelta)) + " removed"
      : "")
  );
}

function gendered(
  gender: Gender | undefined,
  male: string,
  female: string,
  other?: string
) {
  return gender === "male"
    ? male
    : gender === "female"
    ? female
    : other ?? female + "/" + male;
}

function depthPrefix(delta: number) {
  const absDelta = Math.abs(delta);
  return repeat("great-", absDelta - 2) + (absDelta > 1 ? "grand" : "");
}

function repeat(str: string, count: number) {
  const arr: string[] = [];
  for (let i = 0; i < count; i++) {
    arr.push(str);
  }
  return arr.join("");
}

function nth(i: number) {
  if (i === 1) {
    return "first";
  } else if (i === 2) {
    return "second";
  } else if (i === 3) {
    return "third";
  } else if (i === 4) {
    return "fourth";
  }
  return i + "th";
}

function times(i: number) {
  if (i === 1) {
    return "once";
  } else if (i === 2) {
    return "twice";
  } else {
    return i + " times";
  }
}

export function createPersonRelationMap(
  focusPersonDocId: string,
  treeNodeByDocIdMap: Map<string, TreeNode>
): Map<string, Relation | undefined> {
  const map = new Map<string, Relation | undefined>();
  for (const node of Array.from(treeNodeByDocIdMap.values())) {
    for (const person of node.people) {
      map.set(
        person.data.docId,
        getRelation(focusPersonDocId, person.data.docId, treeNodeByDocIdMap)
      );
    }
  }
  return map;
}
