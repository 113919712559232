import { ImageDoc } from "../../../firebase/images/types";
import { useImageDocMap } from "../../../firebase/images/use_images";
import { Thumbnail } from "../thumbnail/Thumbnail";
import "./PhotoGallery.scss";

export const PhotoGallery = (props: { imageDocIds: string[] }) => {
  const imageDocMap = useImageDocMap(props.imageDocIds);

  const imageDocs = props.imageDocIds
    .map((docId) => imageDocMap?.[docId])
    .filter((doc) => !!doc) as ImageDoc[];

  return (
    <div className='PhotoGalleryContents'>
      {imageDocs &&
        imageDocs.map((imageDoc, index) => (
          <Thumbnail
            imageDoc={imageDoc}
            altText='Photo thumbnail'
            classes='PhotoGalleryThumbnail'
            options={{
              linkToViewImage: {
                initialViewIndex: index,
                imageDocIds: imageDocs.map((doc) => doc.docId),
              },
            }}
            key={imageDoc.docId}
          />
        ))}
    </div>
  );
};
