import { DataMap } from "@jishikura/react-subscribable";
import { PersonDoc, PersonDocData } from "../../firebase/people/people";
import { Relation, createPersonRelationMap } from "./relation";
import { TreeNode, createTreeNodeByDocIdMap } from "./tree";

// getTreeNodeByDocIdMap

let memoizedTreeNodeByDocIdMap:
  | [personMap: DataMap<PersonDocData>, treeNodeMap: Map<string, TreeNode>]
  | undefined = undefined;

export function getTreeNodeByDocIdMap(
  personMap?: DataMap<PersonDocData>
): Map<string, TreeNode> {
  if (!personMap) {
    return new Map();
  }
  if (memoizedTreeNodeByDocIdMap?.[0] === personMap) {
    return memoizedTreeNodeByDocIdMap[1];
  }
  const treeNodeByDocId = createTreeNodeByDocIdMap(
    Object.values(personMap).filter((person) => !!person) as PersonDoc[]
  );
  memoizedTreeNodeByDocIdMap = [personMap, treeNodeByDocId];
  return treeNodeByDocId;
}

// getPersonRelationMap

let memoizedPersonRelationMap:
  | [
      personDocId: string,
      personMap: DataMap<PersonDocData>,
      relationMap: Map<string, Relation | undefined>
    ]
  | undefined = undefined;

export function getPersonRelationMap(
  personDocId: string,
  personMap?: DataMap<PersonDocData>
): Map<string, Relation | undefined> {
  if (!personMap) {
    return new Map();
  }
  if (
    memoizedPersonRelationMap?.[0] === personDocId &&
    memoizedPersonRelationMap?.[1] === personMap
  ) {
    return memoizedPersonRelationMap[2];
  }
  const relationMap = createPersonRelationMap(
    personDocId,
    getTreeNodeByDocIdMap(personMap)
  );
  memoizedPersonRelationMap = [personDocId, personMap, relationMap];
  return relationMap;
}
