import { FamilyTree } from "../familytree/FamilyTree";
import { TreeConfig } from "../familytree/render_config";
import { PersonRenderState } from "../familytree/utils/types";
import "./ThumbnailFamilyTree.scss";

export const ThumbnailFamilyTree = (props: { focusPersonDocId: string }) => {
  return (
    <FamilyTree
      personDocId={props.focusPersonDocId}
      treeConfig={{
        nodeSize: 12,
        siblingMargin: 16,
        levelSpacing: 32,
        spouseSpacing: 8,
      }}
      personRenderer={ThumbnailFamilyTreePerson}
    />
  );
};

const ThumbnailFamilyTreePerson = (props: {
  personRenderState: PersonRenderState;
  config: TreeConfig;
  isSelected?: boolean;
}) => {
  const renderState = props.personRenderState;
  const { nodeSize } = props.config;
  return (
    <div
      className='ThumbnailFamilyTree-Person'
      style={{
        backgroundColor: props.isSelected ? "rgb(204, 238, 188)" : "white",
        borderColor: "gray",
        borderRadius: nodeSize / 2,
        height: nodeSize,
        left: renderState.x - nodeSize / 2,
        opacity: renderState.opacity,
        top: renderState.y - nodeSize / 2,
        width: nodeSize,
      }}
    ></div>
  );
};
