import { dismissDialog } from "./dialog_api";

export const AlertDialog = (props: { message: string }) => {
  return (
    <div className='Dialog-AlertDialog'>
      {props.message}
      <div className='Dialog-AlertDialog-ButtonContainer'>
        <button onClick={dismissDialog}>Ok</button>
      </div>
    </div>
  );
};
