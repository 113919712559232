import { useState } from "react";
import { useImageDocData } from "../../../firebase/images/use_images";
import { PersonDocData } from "../../../firebase/people/people";
import { showImageViewer } from "../../imagelayer/image_layer_api";
import { FaceImage } from "../faceimage/FaceImage";
import "./ProfilePic.scss";

export const ProfilePic = (props: {
  docId: string;
  data: PersonDocData;
  size: number;
  ignoreClick?: boolean;
  withSlideshow?: { lingerTimeMs: number; transitionTimeMs: number };
  classes?: string;
}) => {
  const imageDocId = props.data.profileImageDocId ?? "";

  const imageDoc = useImageDocData(imageDocId);
  const [imageLoaded, setImageLoaded] = useState(false);

  const imageClasses = `ProfilePic ${props.classes ?? ""}`;
  return (
    <div className='ProfilePicContainer'>
      {!imageLoaded && (
        <div
          className={imageClasses + " ProfilePicWithNoImage"}
          style={{
            height: "" + props.size + "px",
            width: "" + props.size + "px",
            fontSize: "" + props.size * 0.4 + "px",
          }}
        >
          {props.data.name.substring(0, 3)}
        </div>
      )}
      {imageDoc && (
        <FaceImage
          imageDocData={imageDoc}
          personDocId={props.docId}
          dim={props.size}
          classes={imageClasses}
          onLoad={() => setImageLoaded(true)}
          onClick={
            props.ignoreClick
              ? undefined
              : (e) => {
                  showImageViewer({ imageDocIds: [imageDocId] });
                  e.stopPropagation();
                }
          }
          style={imageLoaded ? {} : { display: "none" }}
        />
      )}
      {/* {props.withSlideshow && (
                <FaceImageSlideshow
                  personDoc={Firebase.people.getPerson(props.data.docId)!}
                  dim={props.size}
                  lingerTimeMs={props.withSlideshow.lingerTimeMs}
                  transitionTimeMs={props.withSlideshow.transitionTimeMs}
                  classes={
                    "ProfilePic ProfilePicSlideshow " + props.className ??
                    ""
                  }
                />
              )} */}
    </div>
  );
};
