import { useSyncExternalStore } from "react";
import { Firebase } from "../firebase";

export function usePersonDocData(docId: string) {
  return useSyncExternalStore(...Firebase.people.syncValue(docId));
}

export function usePersonMap() {
  return useSyncExternalStore(...Firebase.people.syncMap());
}

export function usePeopleFetchStatus() {
  return useSyncExternalStore(...Firebase.people.syncFetchAllState());
}
