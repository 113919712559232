import { Page } from "./page";

export const HASH_KEY = {
  scrollTop: "top",
  imageLayerState: "is",
  imageIndex: "ii",
  imageDocIds: "id",
} as const;

export type HashKey = keyof typeof HASH_KEY;

export function pushNewPageUrl(page: Page) {
  switch (page.kind) {
    case "home":
      pushState("");
      break;
    case "person":
      pushState(`person/${page.personDocId}`);
      break;
    case "editPerson":
      pushState(`person/${page.personDocId}/edit`);
      break;
  }
}

export function getPageFromUrl(): Page {
  const url = new URL(window.location.href);
  const splitPath = url.pathname.split("/").filter((s) => !!s);
  if (splitPath[0] === "person") {
    if (splitPath[2] === "edit") {
      return { kind: "editPerson", personDocId: splitPath[1] };
    } else {
      return { kind: "person", personDocId: splitPath[1] };
    }
  }
  return { kind: "home" };
}

export function replaceUrlHash(
  update: Partial<Record<HashKey, string | null>>
) {
  const urlSearchParams = new URLSearchParams(
    window.location.hash.substring(1)
  );
  for (const [key, value] of Object.entries(update)) {
    if (value === null) {
      urlSearchParams.delete(HASH_KEY[key as HashKey]);
    } else {
      urlSearchParams.set(HASH_KEY[key as HashKey], value);
    }
  }
  const url = new URL(window.location.href);
  url.hash = `#${urlSearchParams.toString()}`;
  window.history.replaceState({}, "", url.toString());
}

export function getUrlHash(key: HashKey) {
  const urlSearchParams = new URLSearchParams(
    window.location.hash.substring(1)
  );
  return urlSearchParams.get(HASH_KEY[key]);
}

function pushState(pathname: string, searchParams: [string, string][] = []) {
  const url = new URL(window.location.href);

  url.pathname = pathname;
  url.hash = "";

  // Clear all search params.
  const keys = url.searchParams.keys();
  for (const key of Array.from(keys)) {
    url.searchParams.delete(key);
  }

  for (const [key, value] of searchParams) {
    url.searchParams.set(key, value);
  }

  window.history.pushState({}, "", url.toString());
}
