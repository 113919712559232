import { getDocList } from "@jishikura/firebase-react";
import { ChangeEvent } from "react";
import { usePersonMap } from "../../firebase/people/use_person";

export const PersonSelector = (props: {
  value: string;
  name?: string;
  excludeDocIds?: string[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}) => {
  const personMap = usePersonMap();

  return (
    <select value={props.value} name={props.name} onChange={props.onChange}>
      <option value=''></option>
      {getDocList(personMap)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((personDoc) => {
          if (props.excludeDocIds?.includes(personDoc.docId)) {
            return null;
          }
          return (
            <option value={personDoc.docId} key={personDoc.docId}>
              {personDoc.name}
            </option>
          );
        })}
    </select>
  );
};

export const CoupleSelector = (props: {
  docIds?: [string, string];
  excludeDocIds?: string[];
  onChange: (personDocId1: string, personDocId2: string) => void;
}) => {
  const personMap = usePersonMap();

  function coupleId(docIds: [string, string]) {
    return docIds.sort().join("+");
  }

  const coupleLabelsById: Map<string, string> = new Map();
  for (const personData of getDocList(personMap)) {
    if (
      !personData.spouseDocId ||
      props.excludeDocIds?.includes(personData.docId)
    ) {
      continue;
    }
    coupleLabelsById.set(
      coupleId([personData.docId, personData.spouseDocId]),
      [personData.docId, personData.spouseDocId]
        .map((id) => personMap?.[id]?.name)
        .sort()
        .join(" and ")
    );
  }

  const sortedCoupleLabelsById = Array.from(coupleLabelsById).sort((a, b) =>
    alphaCmp(a[1], b[1])
  );

  const selectedId = props.docIds ? coupleId(props.docIds) : "";
  const selectedName = coupleLabelsById.get(selectedId) ?? "";
  return (
    <select
      value={selectedId}
      name={selectedName}
      onChange={(e) => {
        props.onChange(...(e.target.value.split("+") as [string, string]));
      }}
    >
      <option value=''></option>
      {sortedCoupleLabelsById.map(([id, label]) => {
        return (
          <option value={id} key={id}>
            {label}
          </option>
        );
      })}
    </select>
  );
};

function alphaCmp(a: string, b: string) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}
