import { PersonDoc } from "../../firebase/people/people";
import { truthyList } from "../../utils/list";

export interface Person {
  node: TreeNode;
  data: PersonDoc;
  parent?: TreeNode;
}

export interface TreeNode {
  depth: number;
  children: Person[];
  people: Person[];
}

export function getParents(node: TreeNode) {
  return truthyList(node.people.map((person) => person.parent));
}

export function getAncestors(node: TreeNode, docId?: string): TreeNode[] {
  const parents = truthyList(
    node.people.map((person) => {
      if (docId) {
        return person.data.docId === docId ? person.parent : undefined;
      }
      return person.parent;
    })
  );
  return [...parents, ...parents.map((parent) => getAncestors(parent)).flat()];
}

export function createTreeNodeByDocIdMap(
  dataList: PersonDoc[]
): Map<string, TreeNode> {
  if (dataList.length === 0) {
    return new Map();
  }

  const dataByDocId = new Map(dataList.map((data) => [data.docId, data]));

  // We assume 1 spouse per person with matching pair.
  const treeNodesByDocId = new Map<string, TreeNode>();

  // Create the nodes without populating the parents or children.
  for (const data of dataList) {
    if (treeNodesByDocId.has(data.docId)) continue;

    const node: TreeNode = {
      depth: 0,
      children: [],
      people: [],
    };
    node.people.push({ node, data });
    treeNodesByDocId.set(data.docId, node);
    if (data.spouseDocId) {
      node.people.push({ node, data: dataByDocId.get(data.spouseDocId)! });
      treeNodesByDocId.set(data.spouseDocId, node);
    }
  }

  // Populate all parent/children fields.
  for (const node of Array.from(new Set(treeNodesByDocId.values()))) {
    for (const person of node.people) {
      const parentNode =
        treeNodesByDocId.get(person.data.parent1DocId ?? "") ||
        treeNodesByDocId.get(person.data.parent2DocId ?? "");

      if (parentNode) {
        parentNode.children.push(person);
        person.parent = parentNode;
      }
    }
  }

  // Update the depth field.
  updateDepths(Array.from(treeNodesByDocId.values())[0]);

  return treeNodesByDocId;
}

function updateDepths(fromNode: TreeNode) {
  fromNode.depth = 0;

  const adjacentUpdated = new Set<TreeNode>();
  const toUpdateAdjacent = [fromNode];

  function setDepth(node: TreeNode, depth: number) {
    node.depth = depth;
    if (!adjacentUpdated.has(node)) {
      toUpdateAdjacent.push(node);
    }
  }

  setDepth(fromNode, 0);
  while (toUpdateAdjacent.length) {
    const node = toUpdateAdjacent.shift()!;
    adjacentUpdated.add(node);
    for (const person of node.people) {
      if (person.parent) {
        setDepth(person.parent, node.depth - 1);
      }
    }
    for (const child of node.children) {
      setDepth(child.node, node.depth + 1);
    }
  }
}
