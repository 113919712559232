import { Firebase } from "../firebase/firebase";
import { DialogLayer } from "./dialoglayer/DialogLayer";
import { ImageLayer } from "./imagelayer/ImageLayer";
import { RequireLoginSplash } from "./requireloginsplash/RequireLoginSplash";
import { useUserData } from "./user/user";

import { getAuth, signOut } from "firebase/auth";
import "./App.scss";
import { Body } from "./body/Body";
import { AnchorButton } from "./widgets/anchorbutton/AnchorButton";

Firebase.getInstance();

export const App = () => {
  const userData = useUserData();

  if (!userData?.user) {
    return <RequireLoginSplash />;
  }

  return (
    <div className='App'>
      <div className='App-Body'>
        <Body />
      </div>
      <div className='App-Footer'>
        Signed in as {userData.user.displayName}. Click&nbsp;
        <AnchorButton onClick={() => signOut(getAuth())}>here</AnchorButton>
        &nbsp;to sign out.
      </div>

      {/* Floating overlays  */}
      <ImageLayer zIndex={999} />
      <DialogLayer zIndex={1000} />
    </div>
  );
};
