import { Doc } from "@jishikura/firebase-react";
import { Timestamp } from "firebase/firestore/lite";

export const STRING_FIELD_KEYS = [
  "name",
  "nicknames",
  "spouseDocId",
  "parent1DocId",
  "parent2DocId",
  "article",
  "profileImgSrc",
  "profileImageDocId",
] as const;

export const CONSTRAINED_STRING_FIELD_KEYS = ["gender"] as const;

export const STRING_FIELD_CONSTRAINTS = {
  gender: ["", "male", "female", "other"] as const,
} satisfies {
  [K in (typeof CONSTRAINED_STRING_FIELD_KEYS)[number]]: Readonly<string[]>;
};

export const DATE_FIELD_KEYS = ["birthday"] as const;

export type Gender = (typeof STRING_FIELD_CONSTRAINTS.gender)[number];

export type PersonDocData = {
  name: string;
  imageDocIds?: string[];
} & {
  [K in (typeof CONSTRAINED_STRING_FIELD_KEYS)[number]]?: (typeof STRING_FIELD_CONSTRAINTS)[K][number];
} & {
  [K in (typeof STRING_FIELD_KEYS)[number]]?: string;
} & {
  [K in (typeof DATE_FIELD_KEYS)[number]]?: Timestamp;
};

export type PersonDoc = Doc<PersonDocData>;

export function personDocDataEquals(p1: PersonDocData, p2: PersonDocData) {
  for (const key of [
    ...STRING_FIELD_KEYS,
    ...CONSTRAINED_STRING_FIELD_KEYS,
    ...DATE_FIELD_KEYS,
  ]) {
    if ((p1[key] ?? "") !== (p2[key] ?? "")) {
      return false;
    }
  }
  return true;
}
