import { getDocList } from "@jishikura/firebase-react";
import { Timestamp } from "firebase/firestore/lite";
import { Firebase } from "../../../firebase/firebase";
import {
  ImageDocData,
  Square,
  TaggedPerson,
} from "../../../firebase/images/types";
import { clone, deepEquals } from "../../../utils/object";

export interface EditState {
  caption?: string;
  taggedPeople: TaggedPerson[];
  date?: Timestamp;
  faceEdit?: TaggedPerson;
}

export const RESIZE_HANDLE_ID = {
  topLeft: "resizeTopLeft",
  bottomRight: "resizeBottomRight",
};

export class Editor {
  readonly scratch: ImageDocData;
  face?: {
    person: TaggedPerson;
    drag?: {
      start: Square;
      startX: number;
      startY: number;
      container: HTMLDivElement;
      targetId: string;
    };
  };

  readonly aspectRatio: number;

  constructor(
    private readonly imageDocId: string,
    private readonly originalImageDoc: ImageDocData,
    private readonly setEditState: (edit: EditState) => void
  ) {
    this.scratch = clone(originalImageDoc);
    this.aspectRatio =
      originalImageDoc.highResImage.width /
      originalImageDoc.highResImage.height;
  }

  get docId() {
    return this.imageDocId;
  }

  commit() {
    this.setEditState(this.getCurrentEditState());
    return this;
  }

  getCurrentEditState(): EditState {
    return {
      caption: this.scratch.caption,
      taggedPeople: this.scratch.taggedPeople,
      date: this.scratch.date,
      faceEdit: this.face?.person,
    };
  }

  finalizedImageDoc() {
    return { ...this.originalImageDoc, ...this.scratch };
  }

  hasEdits() {
    return !deepEquals(this.originalImageDoc, this.scratch);
  }

  updateCaption(caption: string) {
    this.scratch.caption = caption;
    return this;
  }

  updateDate(date?: Timestamp) {
    this.scratch.date = date;
    return this;
  }

  addTaggedPerson() {
    const allDocIds = getDocList(Firebase.people.getAllCached()).map(
      (doc) => doc.docId
    );
    this.scratch.taggedPeople.push({
      personDocId: findFirstExcluding(allDocIds, this.getTaggedPeopleDocIds())!,
    });
    return this;
  }

  removeTaggedPerson(person: TaggedPerson) {
    this.scratch.taggedPeople.splice(
      this.scratch.taggedPeople.indexOf(person),
      1
    );
    if (this.face?.person === person) {
      this.face = undefined;
    }
    return this;
  }

  updateTaggedPerson(index: number, docId: string) {
    this.scratch.taggedPeople[index].personDocId = docId;
    return this;
  }

  addFace(index: number) {
    this.scratch.taggedPeople[index].faceBounds = {
      left: 0.4,
      top: 0.4,
      width: 0.2 * (this.aspectRatio > 1 ? 1 : this.aspectRatio),
    };
    return this;
  }

  startEditingFace(personDocId: string) {
    this.face = {
      person: this.scratch.taggedPeople.find(
        (person) => person.personDocId === personDocId
      )!,
    };
    return this;
  }

  startFaceDrag(
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) {
    if (this.face === undefined) return;

    if (e.type === "touchstart" && (e as React.TouchEvent).touches.length > 1) {
      this.stopFaceDrag();
      return;
    }

    const startPos = getClientPos(e);
    this.face!.drag = {
      start: clone(this.face!.person.faceBounds!),
      startX: startPos.x,
      startY: startPos.y,
      container: e.currentTarget as HTMLDivElement,
      targetId: (e.target as HTMLDivElement).id,
    };

    if (e.type.startsWith("mouse")) {
      document.body.addEventListener("mousemove", this.pointerMove);
      document.body.addEventListener("mouseup", this.stopFaceDrag);
    } else {
      this.face.drag.container.addEventListener("touchmove", this.pointerMove, {
        passive: false,
        capture: true,
      });
      this.face.drag.container.addEventListener("touchend", this.stopFaceDrag);
    }
  }

  private readonly pointerMove = (e: MouseEvent | TouchEvent) => {
    const drag = this.face?.drag;
    if (!drag) {
      this.stopFaceDrag();
      return;
    }

    const { x, y } = getClientPos(e);
    const deltaX = (x - drag.startX) / drag.container.clientWidth;
    const deltaY = (y - drag.startY) / drag.container.clientHeight;

    const faceBounds = this.face!.person.faceBounds!;
    if (drag.targetId === RESIZE_HANDLE_ID.bottomRight) {
      faceBounds.width = drag.start.width + Math.min(deltaX, deltaY);
      faceBounds.width = clamp(faceBounds.width, 0.05, 1 - faceBounds.left);
      faceBounds.width = Math.min(
        faceBounds.width,
        (1 - faceBounds.top) / this.aspectRatio
      );
    } else if (drag.targetId === RESIZE_HANDLE_ID.topLeft) {
      const minDeltaWidth = Math.max(
        0.05 - drag.start.width,
        this.getHeight(0.05 - drag.start.width)
      );

      let delta = Math.max(-deltaX, -deltaY);
      delta = Math.max(delta, minDeltaWidth);
      delta = Math.min(
        delta,
        Math.min(drag.start.left, drag.start.top / this.aspectRatio)
      );

      faceBounds.width = drag.start.width + delta;
      faceBounds.left = drag.start.left + drag.start.width - faceBounds.width;
      faceBounds.top =
        drag.start.top + this.getHeight(drag.start.width - faceBounds.width);
    } else {
      faceBounds.left = drag.start.left + deltaX;
      faceBounds.left = clamp(faceBounds.left, 0, 1 - faceBounds.width);

      faceBounds.top = drag.start.top + deltaY;
      faceBounds.top = clamp(
        faceBounds.top,
        0,
        1 - this.getHeight(faceBounds.width)
      );
    }

    this.commit();
    e.preventDefault();
  };

  private readonly stopFaceDrag = () => {
    if (this.face?.drag) {
      document.body.removeEventListener("mousemove", this.pointerMove);
      document.body.removeEventListener("mouseup", this.stopFaceDrag);
      this.face.drag.container.removeEventListener(
        "touchmove",
        this.pointerMove
      );
      this.face.drag.container.removeEventListener(
        "touchend",
        this.stopFaceDrag
      );
      this.face.drag = undefined;
    }
  };

  getTaggedPeopleDocs() {
    return this.scratch.taggedPeople.map(({ personDocId }) => ({
      docId: personDocId,
      ...Firebase.people.getLocal(personDocId)!,
    }));
  }

  getTaggedPeopleDocIds(excluding?: string[]) {
    return excludeList(
      this.getTaggedPeopleDocs().map(({ docId }) => docId),
      excluding ?? []
    );
  }

  taggedPeopleCount() {
    return this.scratch.taggedPeople.length;
  }

  getHeight(boundsWidth: number) {
    return boundsWidth * this.aspectRatio;
  }
}

function excludeList<T>(list: T[], exclude: T[]): T[] {
  const set = new Set<T>(exclude);
  return list.filter((v) => !set.has(v));
}

function findFirstExcluding<T>(list: T[], exclude: T[]): T | undefined {
  return excludeList(list, exclude)[0];
}

function clamp(x: number, min: number, max: number) {
  return Math.min(Math.max(x, min), max);
}

function getClientPos(e: any) {
  return e.type.startsWith("mouse")
    ? { x: e.clientX, y: e.clientY }
    : { x: e.touches[0].clientX, y: e.touches[0].clientY };
}
