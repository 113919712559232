import { SubscribableData } from "@jishikura/react-subscribable";
import { useSyncExternalStore } from "react";
import { deepEquals } from "../../../utils/object";
import {
  getPageFromUrl,
  getUrlHash,
  pushNewPageUrl,
  replaceUrlHash,
} from "./url";

export type Page = HomePage | PersonPage | EditPersonPage;

export interface HomePage {
  kind: "home";
}

export interface PersonPage {
  kind: "person";
  personDocId: string;
}

export interface EditPersonPage {
  kind: "editPerson";
  personDocId: string;
}

export function setPage(page: Page, onUrlNavigate?: boolean) {
  PageManager.instance.setPage(page, onUrlNavigate);
}

// ============================================================================

export function usePage() {
  return useSyncExternalStore(...PageManager.instance.sync());
}

// ============================================================================

class PageManager {
  private page: SubscribableData<Page>;

  private constructor() {
    this.page = new SubscribableData(getPageFromUrl());

    document.addEventListener("scroll", this.onScroll);
    window.addEventListener("popstate", this.onPopUrlState);
  }

  setPage(page: Page, onUrlNavigate?: boolean) {
    if (deepEquals(page, this.page.get())) {
      return;
    }

    this.page.set(page);
    if (this.pendingScrollTopPushTimeout) {
      window.clearTimeout(this.pendingScrollTopPushTimeout);
      this.pendingScrollTopPushTimeout = undefined;
    }
    const currScrollTop = document.documentElement.scrollTop;
    if (onUrlNavigate) {
      const restoreScrollTop = Number(getUrlHash("scrollTop"));
      if (restoreScrollTop) {
        window.requestAnimationFrame(() => {
          document.documentElement.scrollTop = restoreScrollTop;
        });
      }
    } else {
      replaceUrlHash({ scrollTop: currScrollTop + "" });
      pushNewPageUrl(page);
      document.documentElement.scrollTop = 0;
    }
  }

  sync() {
    return this.page.sync();
  }

  private readonly onPopUrlState = () => {
    this.setPage(getPageFromUrl(), /* onUrlNavigate= */ true);
  };

  private pendingScrollTopPushTimeout?: number;
  private readonly onScroll = () => {
    if (this.pendingScrollTopPushTimeout !== undefined) {
      return;
    }
    this.pendingScrollTopPushTimeout = window.setTimeout(() => {
      replaceUrlHash({ scrollTop: document.documentElement.scrollTop + "" });
      this.pendingScrollTopPushTimeout = undefined;
    }, 500);
  };

  static instanceInternal?: PageManager;
  static get instance(): PageManager {
    if (!this.instanceInternal) {
      this.instanceInternal = new PageManager();
    }
    return this.instanceInternal;
  }
}
