import { getDocList } from "@jishikura/firebase-react";
import { deleteField, writeBatch } from "firebase/firestore/lite";
import { erase } from "../../utils/list";
import { unionSet } from "../../utils/set";
import { Firebase } from "../firebase";
import { ImageDocData, StoredImageData } from "../images/types";

export async function updateImage(
  imageDocId: string,
  imageDocData: Partial<ImageDocData>
) {
  const originalImage = await Firebase.images.get(imageDocId);
  if (!originalImage) {
    throw new Error("Cannot find imageDoc");
  }

  const updateLocalCbs: (() => void)[] = [];

  const batch = writeBatch(Firebase.db);
  batch.update(Firebase.images.getDocRef(imageDocId), imageDocData);
  updateLocalCbs.push(() =>
    Firebase.images.update(imageDocId, imageDocData, { onlyCache: true })
  );

  if (imageDocData.taggedPeople) {
    const originalPersonDocIds = new Set(
      originalImage.taggedPeople.map((people) => people.personDocId)
    );
    const newPersonDocIds = new Set(
      imageDocData.taggedPeople.map((people) => people.personDocId)
    );
    const allPersonDocIds = unionSet(originalPersonDocIds, newPersonDocIds);
    for (const personDocId of Array.from(allPersonDocIds)) {
      const imageDocIds =
        Firebase.people.getLocal(personDocId)?.imageDocIds ?? [];
      if (
        newPersonDocIds.has(personDocId) &&
        originalPersonDocIds.has(personDocId)
      ) {
        // No-op, already in both.
      } else if (originalPersonDocIds.has(personDocId)) {
        // Remove imageDoc from removed person.
        erase(imageDocIds, imageDocId);
        const personDoc = Firebase.people.getLocal(personDocId)!;
        batch.update(Firebase.people.getDocRef(personDocId), {
          imageDocIds,
          ...(personDoc.profileImageDocId === imageDocId
            ? { profileImageDocId: deleteField() }
            : {}),
        });
        updateLocalCbs.push(() =>
          Firebase.people.update(
            personDocId,
            {
              imageDocIds,
              ...(personDoc.profileImageDocId === imageDocId
                ? { profileImageDocId: undefined }
                : {}),
            },
            { onlyCache: true }
          )
        );
      } else {
        // Add imageDoc to the newly added person.
        const newImageDocIds = [...imageDocIds, imageDocId];
        batch.update(Firebase.people.getDocRef(personDocId), {
          imageDocIds: newImageDocIds,
        });
        updateLocalCbs.push(() =>
          Firebase.people.update(
            personDocId,
            {
              imageDocIds: newImageDocIds,
            },
            { onlyCache: true }
          )
        );
      }
    }
  }

  await batch.commit();
  updateLocalCbs.forEach((cb) => cb());
}

export async function deleteImage(imageDocId: string) {
  const imageDoc = await Firebase.images.get(imageDocId);

  const updateLocalCbs: (() => Promise<void>)[] = [];

  const batch = writeBatch(Firebase.db);
  batch.delete(Firebase.images.getDocRef(imageDocId));
  updateLocalCbs.push(() =>
    Firebase.images.delete(imageDocId, { onlyCache: true })
  );
  for (const personDoc of getDocList(Firebase.people.getAllCached())) {
    const imageDocIds = personDoc.imageDocIds;
    if (imageDocIds?.includes(imageDocId)) {
      const removed = [...imageDocIds];
      erase(removed, imageDocId);
      batch.update(Firebase.people.getDocRef(personDoc.docId), {
        imageDocIds: removed,
        ...(personDoc.profileImageDocId === imageDocId
          ? { profileImageDocId: deleteField() }
          : {}),
      });
      updateLocalCbs.push(() =>
        Firebase.people.update(
          personDoc.docId,
          {
            imageDocIds: removed,
            ...(personDoc.profileImageDocId === imageDocId
              ? { profileImageDocId: undefined }
              : {}),
          },
          { onlyCache: true }
        )
      );
    }
  }

  await Promise.allSettled([
    batch.commit(),
    deleteIfCloud(imageDoc?.highResImage),
    deleteIfCloud(imageDoc?.thumbnailImage),
  ]);
  await Promise.all(updateLocalCbs.map((cb) => cb()));
}

async function deleteIfCloud(image?: StoredImageData) {
  if (image?.kind === "cloud") {
    await Firebase.storage.delete(image.fullPath);
  }
}
