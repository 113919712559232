import { PersonDocData } from "../../../../firebase/people/people";
import {
  usePersonDocData,
  usePersonMap,
} from "../../../../firebase/people/use_person";
import { getPersonRelationMap } from "../../../../utils/people/memoized";
import { getRelationString } from "../../../../utils/people/relation";
import { capitalizeFirstLetter } from "../../../../utils/string";
import { AnchorButton } from "../../../widgets/anchorbutton/AnchorButton";
import { CloseButton } from "../../../widgets/closebutton/CloseButton";
import { ProfilePic } from "../../../widgets/profilepic/ProfilePic";
import { setPage } from "../../pageapi/page";
import { FamilyTree } from "../familytree/FamilyTree";
import { PersonRenderState } from "../familytree/utils/types";
import "./FullscreenFamilyTree.scss";

export const FullscreenFamilyTreeOverlay = (props: {
  personDocId: string;
  dismiss: () => void;
}) => {
  return (
    <div className='FullscreenFamilyTree-Popup'>
      <FullscreenFamilyTree
        focusPersonDocId={props.personDocId}
        dismiss={props.dismiss}
      />
      <FocusPerson docId={props.personDocId} />
      <CloseButton
        onClick={props.dismiss}
        classes='FullscreenFamilyTree-Popup-CloseButton'
      />
    </div>
  );
};

const FocusPerson = (props: { docId: string }) => {
  const personDoc = usePersonDocData(props.docId);

  if (!personDoc) {
    return null;
  }

  return (
    <div className='FullscreenFamilyTree-Popup-FocusPerson'>
      <ProfilePic
        docId={props.docId}
        data={personDoc}
        size={72}
        classes='FullscreenFamilyTree-Person-Pic'
      />
      <div className='FullscreenFamilyTree-Popup-FocusPerson-Title'>{`${getFirstName(
        personDoc
      )}'s Family Tree`}</div>
    </div>
  );
};

const FullscreenFamilyTree = (props: {
  focusPersonDocId: string;
  dismiss: () => void;
}) => {
  const focusPersonDocId = props.focusPersonDocId;

  const focusPersonDoc = usePersonDocData(focusPersonDocId);
  if (!focusPersonDoc) {
    return null;
  }

  const relationPrefix = getFirstName(focusPersonDoc) + "'s ";

  const dismiss = props.dismiss;
  return (
    <FamilyTree
      personDocId={focusPersonDocId}
      focusBackground={(props) => (
        <FamilyTreeFocusBackground
          personRenderState={props.personRenderState}
        />
      )}
      treeConfig={{
        nodeSize: 0,
        siblingMargin: 160,
        levelSpacing: 224,
        spouseSpacing: 144,
        outerMargin: {
          horizontal: window.innerWidth * 0.8,
          vertical: window.innerHeight * 0.8,
        },
      }}
      personRenderer={(props) => (
        <FamilyTreePerson
          personRenderState={props.personRenderState}
          isSelected={props.isSelected}
          relationPrefix={relationPrefix}
          dismiss={dismiss}
          focusPersonDocId={focusPersonDocId}
        />
      )}
    />
  );
};

const FamilyTreeFocusBackground = (props: {
  personRenderState: PersonRenderState;
}) => {
  return (
    <div
      className='FullscreenFamilyTree-FocusBackground'
      style={{
        left: `${props.personRenderState.x}px`,
        top: `${props.personRenderState.y}px`,
      }}
    ></div>
  );
};

const FamilyTreePerson = (props: {
  personRenderState: PersonRenderState;
  focusPersonDocId: string;
  relationPrefix: string;
  dismiss: () => void;
  isSelected?: boolean;
}) => {
  const personMap = usePersonMap();

  if (!props || !personMap) {
    return null;
  }

  const renderState = props.personRenderState;
  if (renderState.opacity === 0) {
    return null;
  }

  const relationMap = getPersonRelationMap(props.focusPersonDocId, personMap);
  const relation = relationMap.get(renderState.person.data.docId);
  const relationString = capitalizeFirstLetter(
    props.relationPrefix +
      getRelationString(relation, renderState.person.data.gender)
  );

  const onClick = (e: React.MouseEvent) => {
    setPage({ kind: "person", personDocId: renderState.person.data.docId });
    props.dismiss();
    e.stopPropagation();
  };

  const isFocusPerson =
    renderState.person.data.docId === props.focusPersonDocId;
  return (
    <div
      className='FullscreenFamilyTree-Person'
      style={{
        left: renderState.x,
        top: renderState.y,
      }}
      ref={(element) => {
        if (isFocusPerson) {
          element?.scrollIntoView({ block: "center", inline: "center" });
        }
      }}
    >
      <ProfilePic
        docId={renderState.person.data.docId}
        data={renderState.person.data}
        size={72}
        classes='FullscreenFamilyTree-Person-Pic'
      />
      <div className='FullscreenFamilyTree-Person-Caption'>
        <AnchorButton
          onClick={onClick}
          className='FullscreenFamilyTree-Person-Caption-Name'
        >
          {renderState.person.data.name}
        </AnchorButton>
        {!isFocusPerson && (
          <div className='FullscreenFamilyTree-Person-Caption-Relation'>
            {relationString}
          </div>
        )}
      </div>
    </div>
  );
};

function getFirstName(data: PersonDocData) {
  return data.name.split(" ")[0];
}
