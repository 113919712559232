import { getDocList } from "@jishikura/firebase-react";
import { Timestamp } from "firebase/firestore/lite";
import React, { ChangeEvent, FormEvent } from "react";
import { useImageDocMap } from "../../../firebase/images/use_images";
import {
  CONSTRAINED_STRING_FIELD_KEYS,
  PersonDoc,
  PersonDocData,
  STRING_FIELD_CONSTRAINTS,
  STRING_FIELD_KEYS,
} from "../../../firebase/people/people";
import { capitalizeFirstLetter } from "../../../utils/string";
import { DatePicker } from "../../widgets/DatePicker";
import { CoupleSelector, PersonSelector } from "../../widgets/PersonSelector";
import { FaceImage } from "../../widgets/faceimage/FaceImage";
import "./EditPersonPage.scss";

interface Props {
  docId: string;
  data: PersonDocData;
  onDataChange: (data: PersonDocData) => void;
}

interface State {
  data: PersonDocData;
}

export class PersonDataEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { data: props.data };
  }

  private onInputChange(e: ChangeEvent) {
    const inputEl =
      e.target instanceof HTMLInputElement
        ? (e.target as HTMLInputElement)
        : e.target instanceof HTMLSelectElement
        ? (e.target as HTMLSelectElement)
        : (e as unknown as { name: string; value: string });
    this.updateState({ [inputEl.name]: inputEl.value } as Partial<PersonDoc>);
  }

  private updateState(p: Partial<PersonDoc>) {
    this.setState((state) => {
      const newState = { data: { ...state.data, ...p } };
      this.props.onDataChange(newState.data);
      return newState;
    });
  }

  override render() {
    return (
      <div>
        <div>
          {this.textInput("name", "Name")}
          {this.textInput("nicknames", "Nicknames")}
          <ProfileImageDocIdSelector
            label='Profile picture'
            selectedDocId={this.state.data.profileImageDocId}
            personDocId={this.props.docId}
            personDocData={this.state.data}
            onChange={(profileImageDocId) =>
              this.updateState({ profileImageDocId })
            }
          />
          {this.personSelector("spouseDocId", "Spouse")}
          {this.coupleSelector("parent1DocId", "parent2DocId", "Parents")}
          {this.constrainedTextInput(
            "gender",
            "Gender",
            STRING_FIELD_CONSTRAINTS.gender as unknown as string[]
          )}
          <DateSelector
            label='Birthday'
            selectedDate={this.state.data["birthday"]}
            onChange={(birthday) => this.updateState({ birthday })}
          />
        </div>
        <div>
          <textarea
            className='EditPersonArticleTextArea'
            name='article'
            value={this.state.data.article ?? ""}
            onInput={(e: FormEvent<HTMLTextAreaElement>) => {
              const textAreaEl = e.target as HTMLTextAreaElement;
              this.updateState({
                article: textAreaEl.value,
              } as Partial<PersonDoc>);
            }}
          />
        </div>
      </div>
    );
  }

  private textInput(name: (typeof STRING_FIELD_KEYS)[number], label: string) {
    const value = (this.state.data[name] ?? "") as string;
    return (
      <DataEditor label={label}>
        <input
          type='text'
          name={name}
          value={value}
          onChange={(e) => void this.onInputChange(e)}
          className='EditPersonTextField'
        />
      </DataEditor>
    );
  }

  private constrainedTextInput(
    name: (typeof CONSTRAINED_STRING_FIELD_KEYS)[number],
    label: string,
    validStrings: string[]
  ) {
    const value = (this.state.data[name] ?? "") as string;
    return (
      <DataEditor label={label}>
        <select
          value={value}
          name={name}
          onChange={(e) => void this.onInputChange(e)}
        >
          {validStrings.map((validString) => (
            <option value={validString} key={validString}>
              {capitalizeFirstLetter(validString)}
            </option>
          ))}
        </select>
      </DataEditor>
    );
  }

  private personSelector(
    name: (typeof STRING_FIELD_KEYS)[number],
    label: string
  ) {
    const value = (this.state.data[name] ?? "") as string;
    return (
      <DataEditor label={label}>
        <PersonSelector
          value={value}
          name={name}
          onChange={(e) => void this.onInputChange(e)}
        />
      </DataEditor>
    );
  }

  private coupleSelector(
    name1: (typeof STRING_FIELD_KEYS)[number],
    name2: (typeof STRING_FIELD_KEYS)[number],
    label: string
  ) {
    const docId1 = (this.state.data[name1] ?? "") as string;
    const docId2 = (this.state.data[name2] ?? "") as string;
    return (
      <DataEditor label={label}>
        <CoupleSelector
          docIds={docId1 && docId2 ? [docId1, docId2] : undefined}
          onChange={(docId1, docId2) => {
            this.updateState({
              [name1]: docId1,
              [name2]: docId2,
            } as Partial<PersonDoc>);
          }}
        />
      </DataEditor>
    );
  }
}

const ProfileImageDocIdSelector = (props: {
  label: string;
  selectedDocId?: string;
  personDocId: string;
  personDocData: PersonDocData;
  onChange: (docId: string) => void;
}) => {
  const imageDocIds = props.personDocData.imageDocIds ?? [];
  const imageDocMap = useImageDocMap(imageDocIds);

  if (imageDocIds.length === 0) {
    return null;
  }

  if (imageDocMap === undefined) {
    return <div>Please wait...</div>;
  }

  const imageDocs = getDocList(imageDocMap);

  const imagesWithFace = imageDocs.filter((imageDoc) =>
    imageDoc.taggedPeople.some(
      (taggedPerson) =>
        taggedPerson.personDocId === props.personDocId &&
        taggedPerson.faceBounds
    )
  );

  const faceImages = imagesWithFace.map((imageDoc) => {
    const isSelected = imageDoc.docId === props.selectedDocId;
    return (
      <FaceImage
        imageDocData={imageDoc}
        dim={64}
        personDocId={props.personDocId}
        key={imageDoc.docId}
        classes={isSelected ? "EditPersonSelectedProfile" : ""}
        onClick={() => props.onChange(imageDoc.docId)}
      />
    );
  });
  return (
    <DataEditor label={props.label}>
      <div className='EditPersonProfileContainer'>{faceImages}</div>
    </DataEditor>
  );
};

const DateSelector = (props: {
  label: string;
  selectedDate: Timestamp | undefined;
  onChange: (date: Timestamp) => void;
}) => {
  return (
    <DataEditor label={props.label}>
      <DatePicker value={props.selectedDate} onChange={props.onChange} />
    </DataEditor>
  );
};

const DataEditor = (props: { label: string; children: JSX.Element }) => {
  return (
    <div className='EditPersonDataEditorContainer'>
      <div className='EditPersonDataEditorLabel'>{props.label}</div>
      <div className='EditPersonDataEditorField'>{props.children}</div>
    </div>
  );
};
