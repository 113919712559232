import { SubscribableData } from "@jishikura/react-subscribable";
import { useSyncExternalStore } from "react";
import { PromptDialogOptions } from "./PromptDialog";
import { DialogData } from "./dialog_data";

// ============================================================================

export function showAlertDialog(message: string) {
  DIALOG_DATA.set({ kind: "alert", message });
}

export function showConfirmDialog(message: string, onConfirm: () => void) {
  DIALOG_DATA.set({ kind: "confirm", message, onConfirm });
}

export function showLoadingDialog() {
  DIALOG_DATA.set({ kind: "loading" });
}

export function showPromptDialog(
  message: string,
  onConfirm: (value: string) => void,
  options?: PromptDialogOptions
) {
  DIALOG_DATA.set({ kind: "prompt", message, onConfirm, options });
}

export function dismissDialog() {
  DIALOG_DATA.set(/* data= */ undefined);
}

// ============================================================================

export function useDialogData() {
  return useSyncExternalStore(...DIALOG_DATA.sync());
}

// ============================================================================

const DIALOG_DATA = new SubscribableData<DialogData | undefined>(undefined);
