export const PrevIcon = (props: { classes?: string }) => {
  return (
    <svg
      className={props.classes ?? ""}
      height='46px'
      id='Capa_1'
      version='1.1'
      viewBox='0 0 26 46'
      width='26px'
      xmlSpace='preserve'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <path
        d='M24.998,40.094c1.338,1.352,1.338,3.541,0,4.893c-1.338,1.35-3.506,1.352-4.846,0L1.004,25.447  c-1.338-1.352-1.338-3.543,0-4.895L20.152,1.014c1.34-1.352,3.506-1.352,4.846,0c1.338,1.352,1.338,3.541,0,4.893L9.295,23  L24.998,40.094z'
        fill='currentColor'
      />
    </svg>
  );
};
