import { FetchStatus } from "@jishikura/firebase-react";
import { usePeopleFetchStatus } from "../../firebase/people/use_person";
import { useUserData } from "../user/user";
import { EditPersonPage } from "./editpersonpage/EditPersonPage";
import { ErrorPage } from "./errorpage/ErrorPage";
import { HomePage } from "./homepage/HomePage";
import { usePage } from "./pageapi/page";
import { PersonPage } from "./personpage/PersonPage";
import { UserPersonPage } from "./userpersonpage/UserPersonPage";

export const Body = () => {
  const peopleFetchStatus = usePeopleFetchStatus();
  const page = usePage();
  const userData = useUserData();

  if (
    peopleFetchStatus.status === FetchStatus.NOT_STARTED ||
    peopleFetchStatus.status === FetchStatus.IN_PROGRESS
  ) {
    return <div>Please wait...</div>;
  }

  if (peopleFetchStatus.status === FetchStatus.FAILED) {
    return <ErrorPage e={peopleFetchStatus.e} />;
  }

  if (!userData?.userDoc?.personDocId) {
    return <UserPersonPage />;
  }

  switch (page.kind) {
    case "person":
      return <PersonPage docId={page.personDocId} />;
    case "editPerson":
      return <EditPersonPage docId={page.personDocId} />;
    default:
      return <HomePage />;
  }
};
