import { getDocList } from "@jishikura/firebase-react";
import { Timestamp } from "firebase/firestore/lite";
import React, { CSSProperties, ChangeEvent, FormEvent } from "react";
import {
  deleteImage,
  updateImage,
} from "../../../firebase/coordinated/images_and_people";
import { ImageDocData, TaggedPerson } from "../../../firebase/images/types";
import { useImageDocData } from "../../../firebase/images/use_images";
import { usePersonMap } from "../../../firebase/people/use_person";
import { getAspectRatio } from "../../../utils/image";
import { getPeopleWithFaceBounds } from "../../../utils/people/tagged_people";
import { toFullPath } from "../../../utils/url";
import {
  dismissDialog,
  showConfirmDialog,
  showLoadingDialog,
  showPromptDialog,
} from "../../dialoglayer/dialog_api";
import { DatePicker } from "../../widgets/DatePicker";
import { PersonSelector } from "../../widgets/PersonSelector";
import { FaceImage } from "../../widgets/faceimage/FaceImage";
import { StoredImage } from "../../widgets/storedimage/StoredImage";
import { hideImageLayer, showImageViewer } from "../image_layer_api";
import { FaceBounds, getFaceBoundsPersonDocId } from "../shared/FaceBounds";
import { FaceLabel } from "../shared/FaceLabel";
import "./ImageEditor.scss";
import { EditState, Editor, RESIZE_HANDLE_ID } from "./editor";

export const ImageEditor = (props: {
  imageDocIds: string[];
  editIndex: number;
}) => {
  const docId = props.imageDocIds[props.editIndex] ?? "";
  const imageDoc = useImageDocData(docId);

  if (!imageDoc) {
    // TODO: Close ImageViewer if fetch failed.
    return null;
  }

  return (
    <ImageEditorInternal
      imageDocId={docId}
      imageDocData={imageDoc}
      imageDocIds={props.imageDocIds}
      editIndex={props.editIndex}
      key={docId}
    />
  );
};

class ImageEditorInternal extends React.Component<
  {
    imageDocId: string;
    imageDocData: ImageDocData;
    imageDocIds: string[];
    editIndex: number;
  },
  {
    editState: EditState;
  }
> {
  private readonly editor: Editor;

  constructor(props: any) {
    super(props);
    this.editor = new Editor(
      props.imageDocId,
      props.imageDocData,
      (editState) => {
        this.setState({ editState });
      }
    );
    this.state = { editState: this.editor.getCurrentEditState() };
  }

  override render() {
    const { editState } = this.state;

    const people = getPeopleWithFaceBounds(editState.taggedPeople);
    const aspectRatio = getAspectRatio(this.editor.scratch);

    const nonEditPeople = people.filter(
      (person) => person !== editState.faceEdit
    );

    return (
      <div className='ImageEditor'>
        <div
          className='ImageEditor-ImageWithOverlays'
          onMouseDown={(e) => this.editor.startFaceDrag(e)}
          onTouchStart={(e) => this.editor.startFaceDrag(e)}
          onClick={(e) => {
            const clickedPersonDocId = getFaceBoundsPersonDocId(e.target);
            if (clickedPersonDocId) {
              this.editor.startEditingFace(clickedPersonDocId).commit();
            }
          }}
          ref={(element) => {
            element?.addEventListener("touchstart", (e) => e.preventDefault(), {
              passive: false,
            });
          }}
        >
          <StoredImage
            storedImage={this.editor.scratch.highResImage}
            altText={editState.caption ?? ""}
            classes='ImageEditor-ImageWithOverlays-Image'
          />
          <div className='ImageEditor-ImageWithOverlays-Scrim'></div>
          {nonEditPeople.map((person) => (
            <FaceBounds
              aspectRatio={aspectRatio}
              person={person}
              classes='ImageEditor-ImageWithOverlays-NonEditBounds'
              key={person.personDocId}
            />
          ))}
          {editState.faceEdit && (
            <FaceLabel
              aspectRatio={aspectRatio}
              person={editState.faceEdit}
              classes='ImageEditor-ImageWithOverlays-EditLabel'
              labelFn={(person) => `${person.name}'s face`}
            />
          )}
          {editState.faceEdit && (
            <FaceBounds
              aspectRatio={aspectRatio}
              person={editState.faceEdit}
              classes='ImageEditor-ImageWithOverlays-EditBounds'
            />
          )}
          {editState.faceEdit && (
            <FaceResizeHandle
              id={RESIZE_HANDLE_ID.topLeft}
              style={{
                left: `${editState.faceEdit!.faceBounds!.left * 100}%`,
                top: `${editState.faceEdit!.faceBounds!.top * 100}%`,
                transform: "translate(-100%, -100%)",
              }}
            />
          )}
          {editState.faceEdit && (
            <FaceResizeHandle
              id={RESIZE_HANDLE_ID.bottomRight}
              style={{
                left: `${
                  (editState.faceEdit!.faceBounds!.left +
                    editState.faceEdit!.faceBounds!.width) *
                  100
                }%`,
                top: `${
                  (editState.faceEdit!.faceBounds!.top +
                    editState.faceEdit!.faceBounds!.width * aspectRatio) *
                  100
                }%`,
              }}
            />
          )}
        </div>
        <CaptionEditor
          value={editState.caption}
          update={(caption) => this.editor.updateCaption(caption).commit()}
        />
        <TaggedPeopleEditor
          value={editState.taggedPeople}
          editor={this.editor}
        />
        <DateEditor
          value={editState.date}
          update={(date) => this.editor.updateDate(date).commit()}
        />
        <div className='ImageEditor-ButtonContainer'>
          <button
            onClick={() => this.save()}
            disabled={!this.editor.hasEdits()}
          >
            Save
          </button>
          <button onClick={() => this.abandon()}>Cancel</button>
          <button
            onClick={() => this.delete()}
            disabled={this.editor.hasEdits()}
          >
            Delete this photo
          </button>
        </div>
      </div>
    );
  }

  private delete() {
    showPromptDialog(
      `Are you sure you want to delete this photo? If so, type "delete".`,
      async () => {
        await deleteImage(this.editor.docId);
        hideImageLayer();
      },
      { requiredValue: "delete" }
    );
  }

  private save() {
    const imageDoc = this.editor.finalizedImageDoc();
    showConfirmDialog(
      "Are you sure you want to save your changes?",
      async () => {
        showLoadingDialog();
        await updateImage(this.editor.docId, imageDoc);
        this.returnToViewer();
        dismissDialog();
      }
    );
  }

  private abandon() {
    if (!this.editor.hasEdits()) {
      this.returnToViewer();
    } else {
      showConfirmDialog("Are you sure you want to cancel your changes?", () =>
        this.returnToViewer()
      );
    }
  }

  private returnToViewer() {
    showImageViewer({
      imageDocIds: this.props.imageDocIds,
      initialViewIndex: this.props.editIndex,
    });
  }
}

const CaptionEditor = (props: {
  value?: string;
  update: (caption: string) => void;
}) => {
  return (
    <textarea
      name='caption'
      className='ImageEditor-CaptionEditor-TextArea'
      value={props.value}
      onInput={(e: FormEvent<HTMLTextAreaElement>) => {
        const textAreaEl = e.target as HTMLTextAreaElement;
        props.update(textAreaEl.value);
      }}
    />
  );
};

const TaggedPeopleEditor = (props: {
  value: TaggedPerson[];
  editor: Editor;
}) => {
  const personMap = usePersonMap();

  const editor = props.editor;

  const removePerson = (person: TaggedPerson) => {
    showConfirmDialog("Untag this person?", () => {
      editor.removeTaggedPerson(person).commit();
    });
  };

  const personEditors = editor.getTaggedPeopleDocs().map((personDoc, index) => {
    const taggedPersonDocId = personDoc.docId;
    const taggedPerson = props.value[index];
    return (
      <div key={index} className='ImageEditor-TaggedPeopleEditor-Person'>
        <PersonSelector
          value={taggedPersonDocId}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            void editor.updateTaggedPerson(index, e.target.value).commit()
          }
          excludeDocIds={editor.getTaggedPeopleDocIds([personDoc.docId])}
        />
        <div className='ImageEditor-TaggedPeopleEditor-Person-Face'>
          {taggedPerson.faceBounds === undefined ? (
            <button
              onClick={() =>
                void editor
                  .addFace(index)
                  .startEditingFace(taggedPersonDocId)
                  .commit()
              }
            >
              Tag Face
            </button>
          ) : (
            <FaceImage
              imageDocData={{
                ...props.editor.scratch,
                taggedPeople: props.value,
              }}
              classes={
                "ImageEditor-TaggedPeopleEditor-Person-Face-Image " +
                (editor.face?.person === taggedPerson
                  ? "ImageEditor-TaggedPeopleEditor-Person-Face-EditImage"
                  : "")
              }
              personDocId={taggedPersonDocId}
              dim={36}
              onClick={() =>
                void editor.startEditingFace(taggedPersonDocId).commit()
              }
            />
          )}
        </div>
        {props.value.length > 1 && (
          <button onClick={() => removePerson(taggedPerson)}>X</button>
        )}
      </div>
    );
  });

  const everyoneIsTagged = props.value.length >= getDocList(personMap).length;
  return (
    <div className='ImageEditor-TaggedPeopleEditor'>
      {personEditors}
      {!everyoneIsTagged && (
        <button onClick={() => editor.addTaggedPerson().commit()}>
          Tag another person
        </button>
      )}
    </div>
  );
};

const DateEditor = (props: {
  value?: Timestamp;
  update: (date: Timestamp) => void;
}) => {
  return (
    <div className='ImageEditor-DateEditor'>
      Date:
      <DatePicker
        className='ImageEditor-DateEditor-Input'
        value={props.value}
        onChange={props.update}
      />
    </div>
  );
};

const FaceResizeHandle = (props: { style: CSSProperties; id: string }) => {
  return (
    <div className='FaceResizeHandle' style={props.style} id={props.id}>
      <img
        src={toFullPath("resize.svg")}
        className='FaceResizeHandle-Image'
        alt='Resize'
      />
    </div>
  );
};
