import { MouseEvent } from "react";

import "./AnchorButton.scss";

export const AnchorButton = (props: {
  children: any;
  onClick: (e: MouseEvent) => void;
  className?: string;
}) => {
  return (
    <button
      className={`AnchorButton ${props.className ?? ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
