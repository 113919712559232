import React, { RefObject } from "react";
import { AnchorButton } from "../widgets/anchorbutton/AnchorButton";
import { dismissDialog } from "./dialog_api";

export interface PromptDialogOptions {
  requiredValue?: string;
}

export class PromptDialog extends React.Component<
  {
    message: string;
    onConfirm: (value: string) => void;
    options?: PromptDialogOptions;
  },
  { value: string }
> {
  private inputRef: RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this.inputRef = React.createRef();
    this.state = { value: "" };
  }

  componentDidMount() {
    this.inputRef.current?.focus();
  }

  override render() {
    const confirmDisabled =
      this.state.value.length === 0 ||
      (this.props.options?.requiredValue !== undefined &&
        this.state.value !== this.props.options.requiredValue);

    return (
      <div className='Dialog-PromptDialog'>
        {this.props.message}
        <input
          type='text'
          value={this.state.value}
          onChange={(e) => void this.setState({ value: e.currentTarget.value })}
          className='EditPersonTextField'
          ref={this.inputRef}
        />
        <div className='Dialog-PromptDialog-ButtonContainer'>
          <button
            onClick={() => {
              dismissDialog();
              this.props.onConfirm(this.state.value);
            }}
            disabled={confirmDisabled}
          >
            Confirm
          </button>
          <AnchorButton onClick={dismissDialog}>Cancel</AnchorButton>
        </div>
      </div>
    );
  }
}
