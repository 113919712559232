import { SubscribableMap } from "@jishikura/react-subscribable";
import {
  deleteObject,
  FirebaseStorage,
  getDownloadURL,
  list,
  ref,
  uploadBytes,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

export class Storage {
  readonly store = new SubscribableMap<string>();

  // Wait on a
  readonly initialListPromise: Promise<void>;

  constructor(private readonly firebaseStorage: FirebaseStorage) {
    this.initialListPromise = new Promise((resolve) => {
      list(ref(this.firebaseStorage), { maxResults: 1 }).then(() => resolve());
    });
  }

  private async waitForSanityCheck() {
    await this.initialListPromise;
  }

  async fetchDownloadUrl(path: string) {
    await this.waitForSanityCheck();
    const url = await getDownloadURL(ref(this.firebaseStorage, path));
    this.store.set(path, url);
    return url;
  }

  async uploadImage(filename: string, file: File) {
    await this.waitForSanityCheck();
    const imageRef = ref(
      this.firebaseStorage,
      `images/${uuidv4()}/${filename}`
    );
    const uploadResult = await uploadBytes(imageRef, file);
    const url = await getDownloadURL(uploadResult.ref);
    this.store.set(imageRef.fullPath, url);
    return uploadResult;
  }

  async delete(fullPath: string) {
    return deleteObject(ref(this.firebaseStorage, fullPath));
  }
}
