import { PersonDocData } from "../../../firebase/people/people";
import { blend, withOpacity } from "../../../utils/color";
import { Relation, getRelationString } from "../../../utils/people/relation";
import { generationColor } from "../../../utils/people/style";
import { capitalizeFirstLetter } from "../../../utils/string";
import { ProfilePic } from "../../widgets/profilepic/ProfilePic";
import { setPage } from "../pageapi/page";
import "./HomePage.scss";

export const ListPeopleEntry = (props: {
  docId: string;
  data: PersonDocData;
  profilePicDim?: number;
  relation?: Relation;
  className?: string;
}) => {
  const relationString = capitalizeFirstLetter(
    getRelationString(props.relation, props.data.gender)
  );
  const baseColor = props.relation
    ? generationColor(props.relation.depthDelta)
    : "rgba(150, 150, 150, 1)";
  return (
    <div
      className={"ListPeopleEntry " + (props.className ?? "")}
      style={{
        backgroundColor: withOpacity(baseColor, 0.1),
      }}
      onClick={(e) => {
        setPage({ kind: "person", personDocId: props.docId });
        e.stopPropagation();
      }}
    >
      {(props.profilePicDim ?? 0) > 0 && props.data.profileImageDocId && (
        <ProfilePic
          docId={props.docId}
          data={props.data}
          size={props.profilePicDim!}
          classes='ListPeopleEntryProfilePic'
          ignoreClick={true}
        />
      )}
      <div>{props.data.name}</div>
      <div
        className='ListPeopleEntryRelation'
        style={{ color: blend(baseColor, "rgba(0, 0, 0, 1)", 0.25) }}
      >
        {props.relation && relationString !== "Self" && relationString}
      </div>
    </div>
  );
};
