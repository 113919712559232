import { useSyncExternalStore } from "react";
import { Firebase } from "../firebase";

export function useStorageDownloadUrl(fullPath: string) {
  const store = Firebase.storage.store;
  if (!store.get(fullPath)) {
    Firebase.storage.fetchDownloadUrl(fullPath);
  }
  return useSyncExternalStore(...store.syncValue(fullPath));
}
