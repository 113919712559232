import { AnchorButton } from "../widgets/anchorbutton/AnchorButton";
import "./Dialog.scss";
import { dismissDialog } from "./dialog_api";

export const ConfirmDialog = (props: {
  message: string;
  onConfirm: () => void;
}) => {
  return (
    <div className='Dialog-ConfirmDialog'>
      {props.message}
      <div className='Dialog-ConfirmDialog-ButtonContainer'>
        <button
          onClick={() => {
            dismissDialog();
            props.onConfirm();
          }}
        >
          Confirm
        </button>
        <AnchorButton onClick={dismissDialog}>Cancel</AnchorButton>
      </div>
    </div>
  );
};
