import clone from "clone";
import { useState } from "react";
import { Firebase } from "../../../firebase/firebase";
import {
  PersonDocData,
  personDocDataEquals,
} from "../../../firebase/people/people";
import { usePersonDocData } from "../../../firebase/people/use_person";
import {
  dismissDialog,
  showConfirmDialog,
  showLoadingDialog,
  showPromptDialog,
} from "../../dialoglayer/dialog_api";
import { PersonWiki } from "../../widgets/personwiki/PersonWiki";
import { setPage } from "../pageapi/page";
import "./EditPersonPage.scss";
import { PersonDataEditor } from "./PersonDataEditor";

export const EditPersonPage = (props: { docId: string }) => {
  const [showPreview, setShowPreview] = useState(false);

  const personDocData = usePersonDocData(props.docId);
  const [liveData, setLiveData] = useState(clone(personDocData));

  if (!personDocData || !liveData) {
    return null;
  }

  const isDirty = !personDocDataEquals(liveData, personDocData);

  const deletePerson = async () => {
    showPromptDialog(
      `Are you sure you want to delete this person? If so, type the person's name (${personDocData.name}) here.`,
      async () => {
        await Firebase.people.delete(props.docId);
        setPage({ kind: "home" });
      },
      { requiredValue: personDocData.name }
    );
  };

  const cancelEdit = () => {
    if (isDirty) {
      showConfirmDialog(
        "Abandon changes?",
        () =>
          void setPage({
            kind: "person",
            personDocId: props.docId,
          })
      );
    } else {
      setPage({
        kind: "person",
        personDocId: props.docId,
      });
    }
  };

  return (
    <div className='EditPerson'>
      <div
        className='EditPerson-Editor'
        style={{ height: showPreview ? "0" : "" }}
      >
        <PersonDataEditor
          docId={props.docId}
          data={personDocData}
          onDataChange={setLiveData}
        />

        <div className='EditPersonButtonContainer'>
          <button
            onClick={() => {
              setShowPreview(true);
              document.documentElement.scrollTo(0, 0);
            }}
            disabled={!isDirty}
          >
            Save
          </button>
          <button onClick={() => void cancelEdit()}>Cancel</button>
          <button onClick={() => void deletePerson()} disabled={isDirty}>
            Delete
          </button>
        </div>
      </div>
      {showPreview && (
        <PreviewPopup
          docId={props.docId}
          docData={liveData}
          onSave={async () => {
            showConfirmDialog("Save changes?", async () => {
              showLoadingDialog();
              await Firebase.people.update(props.docId, liveData);
              dismissDialog();
              setShowPreview(false);
              setPage({
                kind: "person",
                personDocId: props.docId,
              });
            });
          }}
          onCancel={() => void setShowPreview(false)}
        />
      )}
    </div>
  );
};

const PreviewPopup = (props: {
  docId: string;
  docData: PersonDocData;
  onSave: () => void;
  onCancel: () => void;
}) => {
  return (
    <div className='EditPersonPreviewContent'>
      <div className='EditPersonPreviewTitle'>
        The following is a preview of your changes. If these changes look good,
        scroll down to save the change.
      </div>

      <div className='EditPersonPreviewPeekContainer'>
        <PersonWiki docId={props.docId} docData={props.docData} />
      </div>

      <div className='EditPersonPreviewButtonContainer'>
        <button
          onClick={() => void props.onSave()}
          className='EditPersonPreviewButton'
        >
          Save
        </button>
        <button onClick={props.onCancel}>Cancel</button>
      </div>
    </div>
  );
};
