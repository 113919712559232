import { TaggedPerson } from "../../../firebase/images/types";

import "./FaceBounds.scss";

export const FaceBounds = (props: {
  aspectRatio: number;
  person: TaggedPerson;
  classes?: string;
}) => {
  const faceId = createBoundsTargetId(props.person.personDocId);
  const { top, left, width } = props.person.faceBounds!;
  return (
    <div
      className={`FaceBounds ${props.classes ?? ""}`}
      style={{
        top: `${top * 100}%`,
        left: `${left * 100}%`,
        height: `${width * props.aspectRatio * 100}%`,
        width: `${width * 100}%`,
      }}
      id={faceId}
    />
  );
};

function createBoundsTargetId(personDocId: string) {
  return "face" + personDocId;
}

export function getFaceBoundsPersonDocId(target: EventTarget) {
  const targetId = target instanceof HTMLDivElement ? target.id : "";
  return targetId.startsWith("face") ? targetId.substring(4) : undefined;
}
