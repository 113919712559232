import imageCompression from "browser-image-compression";
import { Firebase } from "../../../firebase/firebase";
import {
  MAX_FULL_RES_SIZE,
  MAX_THUMBNAIL_DIM,
  MB,
} from "../../../firebase/images/constants";
import { StoredImageData } from "../../../firebase/images/types";
import { concatUniqueList } from "../../../utils/list";

interface Preview {
  readonly promise: Promise<void>;
  readonly element: HTMLImageElement;
}

export class ImageAdder {
  private readonly preview: Preview;

  private commitBlock: {
    promise: Promise<void>;
    resolve: () => void;
    reject: () => void;
  };

  private readonly thumbnailPromise: Promise<StoredImageData>;
  private readonly highResPromise: Promise<StoredImageData>;

  constructor(readonly file: File) {
    this.preview = createPreview(file);

    let commitBlockResolve;
    let commitBlockReject;
    const commitBlockPromise = new Promise<void>((resolve, reject) => {
      commitBlockResolve = resolve;
      commitBlockReject = reject;
    });
    this.commitBlock = {
      promise: commitBlockPromise,
      resolve: commitBlockResolve as unknown as () => void,
      reject: commitBlockReject as unknown as () => void,
    };

    this.highResPromise = createHighResAsync(
      file,
      this.preview,
      this.commitBlock.promise
    );
    this.thumbnailPromise = createThumbnailAsync(
      file,
      this.preview,
      this.highResPromise,
      this.commitBlock.promise
    );
  }

  async waitForPreviewReady() {
    await this.preview.promise;
  }

  getPreviewImageSrc() {
    return this.preview.element.src;
  }

  async commit(personDocId: string) {
    this.commitBlock.resolve();

    const [thumbnailImage, highResImage] = await Promise.all([
      this.thumbnailPromise,
      this.highResPromise,
    ]);

    const imageDoc = await Firebase.images.add({
      highResImage,
      thumbnailImage,
      taggedPeople: [{ personDocId }],
    });
    await Firebase.people.update(personDocId, {
      imageDocIds: concatUniqueList(
        Firebase.people.getLocal(personDocId)?.imageDocIds,
        imageDoc.id
      ),
    });
    return imageDoc;
  }
}

function createPreview(file: File) {
  const element = new Image();
  return { element, promise: createPreviewPromise(element, file) };
}

async function createPreviewPromise(element: HTMLImageElement, file: File) {
  const promise = new Promise<void>((resolve, reject) => {
    element.onload = () => resolve();
    element.onerror = reject;
  });
  const dataUrl = await imageCompression.getDataUrlFromFile(file);
  element.src = dataUrl;
  return promise;
}

async function gePreviewNaturalSize(preview: Preview) {
  await preview.promise;
  return getNaturalSize(preview.element);
}

async function createHighResAsync(
  file: File,
  preview: Preview,
  commitBlock: Promise<void>
): Promise<StoredImageData> {
  const naturalSize = await gePreviewNaturalSize(preview);
  if (file.size >= MAX_FULL_RES_SIZE) {
    const compressed = await compressFile(file, {
      maxSizeMB: MAX_FULL_RES_SIZE / MB,
    });
    return createFileImageAsync(compressed.file, compressed.size, commitBlock);
  } else {
    return createFileImageAsync(file, naturalSize, commitBlock);
  }
}

async function createThumbnailAsync(
  file: File,
  preview: Preview,
  highResPromise: Promise<StoredImageData>,
  commitBlock: Promise<void>
): Promise<StoredImageData> {
  const naturalSize = await gePreviewNaturalSize(preview);

  // If the image is already smaller than a thumbnail, use the highRes.
  if (
    naturalSize.width <= MAX_THUMBNAIL_DIM &&
    naturalSize.height <= MAX_THUMBNAIL_DIM
  ) {
    return highResPromise;
  }

  const compressed = await compressFile(file, {
    maxWidthOrHeight: MAX_THUMBNAIL_DIM,
    initialQuality: 0.95,
  });
  return createFileImageAsync(compressed.file, compressed.size, commitBlock);
}

async function createFileImageAsync(
  file: File,
  { width, height }: { width: number; height: number },
  commitBlock: Promise<void>
): Promise<StoredImageData> {
  await commitBlock;
  const uploadResult = await Firebase.storage.uploadImage(file.name, file);
  return { kind: "cloud", fullPath: uploadResult.ref.fullPath, width, height };
}

async function compressFile(
  file: File,
  options: {
    maxWidthOrHeight?: number;
    maxSizeMB?: number;
    initialQuality?: number;
  }
) {
  const compressedFile = await imageCompression(file, {
    ...options,
    fileType: "image/webp",
  });
  const size = await getFileNaturalSize(compressedFile);
  return { file: compressedFile, size };
}

async function getFileNaturalSize(file: File) {
  const dataUrl = await imageCompression.getDataUrlFromFile(file);
  const img = await loadImage(dataUrl);
  return getNaturalSize(img);
}

function getNaturalSize(img: HTMLImageElement) {
  if (!img.src || !img.complete || img.naturalWidth === 0) {
    throw new Error(`Image not loaded: ${img}`);
  }
  return {
    width: img.naturalWidth,
    height: img.naturalHeight,
  };
}

async function loadImage(src: string): Promise<HTMLImageElement> {
  const img = new Image();
  const load = new Promise<void>((resolve, reject) => {
    img.onload = () => resolve();
    img.onerror = reject;
  });
  img.src = src;
  await load;
  return img;
}
