import React from "react";
import { hideImageLayer } from "../image_layer_api";

import { replaceUrlHash } from "../../body/pageapi/url";
import { CloseButton } from "../../widgets/closebutton/CloseButton";
import { PrevIcon } from "../../widgets/icons/PrevIcon";
import "./ImageViewer.scss";
import { SingleImageViewer } from "./SingleImageViewer";

const ANIMATION_MS = 200;

interface Animation {
  transform: string;
}

interface Drag {
  startClientX: number;
  translateX: number;
  lastTimestamp: number;
  lastClientX: number;
  velocity: number;
}

export class ImageViewer extends React.Component<
  {
    imageDocIds: string[];
    initialIndex: number;
  },
  {
    centerIndex: number;
    animation?: Animation;
    drag?: Drag;
    clientWidth: number;
    clientHeight: number;
  }
> {
  private readonly resizeObserver = new ResizeObserver(
    (entries: ResizeObserverEntry[]) => void this.onResize(entries[0])
  );

  constructor(props: any) {
    super(props);
    this.state = {
      centerIndex: props.initialIndex,
      clientWidth: window.innerWidth,
      clientHeight: window.innerHeight,
    };
  }

  override componentDidUpdate(ignored: any, prevState: any) {
    if (
      prevState === undefined ||
      prevState.centerIndex !== this.state.centerIndex
    ) {
      replaceUrlHash({ imageIndex: this.state.centerIndex + "" });
    }
  }

  override render() {
    const isFirstImage = this.state.centerIndex === 0;
    const isLastImage =
      this.state.centerIndex === this.props.imageDocIds.length - 1;

    return (
      <div
        className='ImageViewer'
        ref={(element) => {
          // element?.clientWidth ?? window.innerHeight;
          this.resizeObserver.disconnect();
          if (element) {
            this.resizeObserver.observe(element);
          }

          // Block scrolling.
          element?.addEventListener("scroll", (e) => e.preventDefault(), {
            passive: false,
          });
          element?.addEventListener("wheel", (e) => e.preventDefault(), {
            passive: false,
          });
          element?.addEventListener(
            "touchmove",
            (e) => {
              if (e.touches.length === 1 && !isZoomedIn()) {
                e.preventDefault();
              }
            },
            {
              passive: false,
            }
          );
        }}
      >
        <div
          className='ImageViewer-SwipeContainer'
          style={{
            ...(this.state.animation
              ? {
                  transform: this.state.animation.transform,
                  transition: `transform ${ANIMATION_MS}ms ease-in-out`,
                }
              : {}),
            ...(this.state.drag
              ? { transform: `translateX(${this.state.drag.translateX}px)` }
              : {}),
          }}
          onTouchStart={(e) => this.handleTouchStart(e)}
          onTouchMove={(e) => this.handleTouchMove(e)}
          onTouchEnd={(e) => this.handleTouchEnd(e)}
        >
          <div className='ImageViewer-SwipeContainer-Single'>
            {!isFirstImage && (
              <SingleImageViewer
                docId={this.props.imageDocIds[this.state.centerIndex - 1]}
                index={this.state.centerIndex - 1}
                isCenter={false}
                key={this.state.centerIndex - 1}
                containerHeight={this.state.clientHeight}
                containerWidth={this.state.clientWidth}
              />
            )}
          </div>
          <div className='ImageViewer-SwipeContainer-Single'>
            <SingleImageViewer
              docId={this.props.imageDocIds[this.state.centerIndex]}
              index={this.state.centerIndex}
              isCenter={true}
              key={this.state.centerIndex}
              containerHeight={this.state.clientHeight}
              containerWidth={this.state.clientWidth}
            />
          </div>
          <div className='ImageViewer-SwipeContainer-Single'>
            {!isLastImage && (
              <SingleImageViewer
                docId={this.props.imageDocIds[this.state.centerIndex + 1]}
                index={this.state.centerIndex + 1}
                isCenter={false}
                key={this.state.centerIndex + 1}
                containerHeight={this.state.clientHeight}
                containerWidth={this.state.clientWidth}
              />
            )}
          </div>
        </div>
        {!isFirstImage && (
          <button
            className='ImageViewer-SwipeButton ImageViewer-SwipeButtonPrev'
            onMouseUp={() => void this.animateTo("left")}
          >
            <PrevIcon classes='ImageViewer-SwipeButton-Icon' />
          </button>
        )}
        {!isLastImage && (
          <button
            className='ImageViewer-SwipeButton ImageViewer-SwipeButtonNext'
            onMouseUp={() => void this.animateTo("right")}
          >
            <PrevIcon classes='ImageViewer-SwipeButton-Icon ImageViewer-SwipeButton-IconNext' />
          </button>
        )}
        <CloseButton
          classes='ImageViewer-CloseButton'
          onClick={() => void hideImageLayer()}
        />
      </div>
    );
  }

  private handleTouchStart(e: React.TouchEvent) {
    if (this.state.animation) return;
    if (e.touches.length > 1) {
      this.handleTouchEnd(e);
      return;
    }

    this.setState({
      drag: {
        translateX: 0,
        startClientX: e.touches[0].clientX,
        lastClientX: e.touches[0].clientX,
        lastTimestamp: performance.now(),
        velocity: 0,
      },
    });
  }

  private handleTouchMove(e: React.TouchEvent) {
    if (isZoomedIn()) {
      return;
    }

    const clientX = e.touches[0].clientX;
    const timestamp = performance.now();
    const dt = (timestamp - this.state.drag!.lastTimestamp) / 1000;
    this.setState({
      drag: {
        translateX: clientX - this.state.drag!.startClientX,
        startClientX: this.state.drag!.startClientX,
        lastClientX: clientX,
        lastTimestamp: timestamp,
        velocity: (clientX - this.state.drag!.lastClientX) / dt,
      },
    });
  }
  private handleTouchEnd(e: React.TouchEvent) {
    const drag = this.state.drag!.translateX;
    const velocity = this.state.drag!.velocity;
    this.setState({ drag: undefined });
    if (
      (drag > window.innerWidth / 2 || velocity > 200) &&
      this.state.centerIndex > 0
    ) {
      this.animateTo("left");
    } else if (
      (drag < -window.innerWidth / 2 || velocity < -200) &&
      this.state.centerIndex < this.props.imageDocIds.length - 1
    ) {
      this.animateTo("right");
    } else {
      this.animateTo("center");
    }
  }

  private animateTo(dest: "left" | "right" | "center") {
    let newCenter: number;
    let transform: string;
    switch (dest) {
      case "left":
        newCenter = this.state.centerIndex - 1;
        transform = "translateX(100vw)";
        break;
      case "center":
        newCenter = this.state.centerIndex;
        transform = "translateX(0)";
        break;
      case "right":
        newCenter = this.state.centerIndex + 1;
        transform = "translateX(-100vw)";
        break;
    }

    this.setState({ animation: { transform } });
    setTimeout(() => {
      this.setState({
        animation: undefined,
        centerIndex: newCenter,
      });
    }, ANIMATION_MS);
  }

  private onResize(entry: ResizeObserverEntry) {
    this.setState({
      clientHeight: entry.contentRect.height,
      clientWidth: entry.contentRect.width,
    });
  }
}

function isZoomedIn() {
  return window.innerWidth < document.body.clientWidth - 20;
}
