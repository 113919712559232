import { TaggedPerson } from "../../../firebase/images/types";
import { PersonDocData } from "../../../firebase/people/people";
import { usePersonDocData } from "../../../firebase/people/use_person";

export const FaceLabel = (props: {
  aspectRatio: number;
  person: TaggedPerson;
  classes?: string;
  labelFn?: (personDocData: PersonDocData) => string;
}) => {
  const personDoc = usePersonDocData(props.person.personDocId);
  if (!personDoc) {
    return null;
  }

  const { top, left, width } = props.person.faceBounds!;
  return (
    <div
      className={props.classes ?? ""}
      style={{
        left: `${(left + width / 2) * 100}%`,
        top: `${(top + width * props.aspectRatio) * 100}%`,
      }}
      key={`label-${props.person.personDocId}`}
    >
      {props.labelFn?.(personDoc) ?? personDoc.name}
    </div>
  );
};
