import { useState } from "react";
import { Firebase } from "../../../firebase/firebase";
import { usePersonDocData } from "../../../firebase/people/use_person";
import {
  dismissDialog,
  showConfirmDialog,
  showLoadingDialog,
} from "../../dialoglayer/dialog_api";
import { useUserData } from "../../user/user";
import { PersonSelector } from "../../widgets/PersonSelector";
import "./UserPersonPage.scss";

export const UserPersonPage = () => {
  const userData = useUserData();
  const [personDocId, setPersonDocId] = useState(
    userData?.userDoc?.personDocId ?? ""
  );
  const personDoc = usePersonDocData(personDocId ?? "");

  if (!userData?.user) {
    return null;
  }

  const user = userData?.user;

  return (
    <div className='UserPerson'>
      <div className='UserPersonHello'>Hello!</div>
      <div className='UserPersonInstructions'>
        <p>Welcome to ishikura.family.</p>
        <p>Please select your name below:</p>
      </div>
      <div className='UserPersonSelectorContainer'>
        <PersonSelector
          value={personDocId ?? ""}
          onChange={(e) => setPersonDocId(e.target.value)}
        />
      </div>
      {personDocId !== "" && (
        <div className='UserPersonButtonContainer'>
          <button
            onClick={() => {
              showConfirmDialog(
                `Hello, ${personDoc?.name}! Did we get your name right?`,
                async () => {
                  showLoadingDialog();
                  await Firebase.users.update(user.uid, {
                    personDocId,
                  });
                  dismissDialog();
                }
              );
            }}
          >
            Yes, I am {personDoc?.name}!
          </button>
        </div>
      )}
    </div>
  );
};
