import { lrp } from "./math";

export function parseRgba(rgba: string): number[] {
  return rgba
    .replace(/^rgba?\(|\s+|\)$/g, "")
    .split(",")
    .map((str) => Number(str));
}

export function toRgba(rgba: number[]) {
  return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3]})`;
}

export function withOpacity(rgba: string, opacity: number): string {
  const parsed = parseRgba(rgba);
  parsed[3] = opacity;
  return toRgba(parsed);
}

export function blend(aRgba: string, bRgba: string, t: number): string {
  const aParsed = parseRgba(aRgba);
  const bParsed = parseRgba(bRgba);
  return toRgba([
    lrp(aParsed[0], bParsed[0], t),
    lrp(aParsed[1], bParsed[1], t),
    lrp(aParsed[2], bParsed[2], t),
    lrp(aParsed[3], bParsed[3], t),
  ]);
}
