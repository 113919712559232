import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { AnchorButton } from "../widgets/anchorbutton/AnchorButton";
import "./RequireLoginSplash.scss";

export const RequireLoginSplash = () => {
  function onLoginClick() {
    signInWithPopup(getAuth(), new GoogleAuthProvider());
  }

  return (
    <div className='SplashContainer'>
      <div className='SplashContents'>
        <div>You have reached a restricted page.</div>

        <div>
          Click&nbsp;
          <AnchorButton onClick={onLoginClick}> here</AnchorButton> to login.
        </div>
      </div>
    </div>
  );
};
